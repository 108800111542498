import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Container,
  Form,
  Grid,
  Label,
  Message,
} from "semantic-ui-react";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useStore } from "../../app/stores/store";
import { useTranslation } from "react-i18next";
import SubscriptionOptions from "./SubscriptionOptions";
import CreditCard from "../../app/common/form/CreditCard";
import PageHeader from "../../app/layout/PageHeader";
import * as Yup from "yup";
import { ErrorMessage, Formik, FormikErrors } from "formik";
import ValidationErrors from "../errors/ValidationErrors";
import MyCheckbox from "../../app/common/form/MyCheckbox";
import { ClientSubscriptionsFormValues } from "../../app/models/clientSubscription";
import { Link, useNavigate } from "react-router-dom";
import sslBadgeGif from "../../app/common/img/ssl_badge.gif";
import creditCardLogos from "../../app/common/img/credit_card_logos.gif";
import sslBadgePng from "../../app/common/img/ssl-badge.png";
import { RegexConstants } from "../../app/common/util/regexConstants";
import {
  creditCardValidationLuhnCheck,
  expirationDateCheck,
} from "../../app/common/util/functions";
import BillingAddressForm from "../../app/common/form/BillingAddressForm";
import SubscriptionMobileMenu from "./SubscriptionMobileMenu";
import SubscriptionContent from "./SubscriptionContent";
import { Application, ClientStatus } from "../../app/models/enums";
import { toast } from "react-toastify";

export default observer(function BillingInformation() {
  const { subscriptionStore, userStore } = useStore();
  const {
    loadingInitial,
    createSubscription,
    loadSubscription,
    updateBillingInformation,
    getCurrentSubscription,
    clientSubscriptionStatus,
  } = subscriptionStore;
  const { getUser, user, checkStatus, initialRedirects } = userStore;
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "subscription"]);

  const [readMoar, setReadMoar] = useState<boolean>(false);
  const [newSubscription, setNewSubscription] = useState<boolean>(true);

  if (user && user?.application == Application.None) initialRedirects(); 


  useEffect(() => {
    loadSubscription().then((result) => {
      if (result?.isTrial) result.isTrial = false;
      setClientSubscriptions(new ClientSubscriptionsFormValues(result));
    });
  }, [loadSubscription]);
  
  useEffect(() => {
    getCurrentSubscription();
  }, [getCurrentSubscription]);

  //TODO refactor the SubscriptionExtensions so that its value is earlier in list
  useEffect(() => {
    setNewSubscription(
      !checkStatus(ClientStatus.Active | ClientStatus.Subscription) ||
        clientSubscriptionStatus.isCancelled || checkStatus(ClientStatus.Active | ClientStatus.SubscriptionExtension)
    );
  }, [user?.status, checkStatus, clientSubscriptionStatus.isCancelled]);



  const [clientSubscriptions, setClientSubscriptions] =
    useState<ClientSubscriptionsFormValues>(
      new ClientSubscriptionsFormValues()
    );

  function handleFormSubmit(
    values: ClientSubscriptionsFormValues,
    setErrors: (errors: FormikErrors<ClientSubscriptionsFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    const action = newSubscription
      ? () => createSubscription(values)
      : () => updateBillingInformation(values);

    action()
      .then(() => {
        getUser();
        setSubmitting(false);
        navigate("/subscription/status");
        toast.success(
          newSubscription
            ? t("toast.paymentSuccessful", { ns: "subscription" }).toString()
            : t("toast.subscriptionUpdated", { ns: "subscription" }).toString()
        );
      })
      .catch((error) => {
        setSubmitting(false);
        setErrors({ error: error });
      });
  }

  const validationSchema = Yup.object({
    agreeTerms: Yup.boolean().test(
      "checkforNew",
      t("subscription.agreeTerms", { ns: "errors" }),
      (item, testContext) => {
        return !newSubscription || testContext.parent.agreeTerms;
      }
    ),
    agreeSubscription: Yup.boolean().test(
      "checkforNew",
      t("subscription.agreeSubscription", { ns: "errors" }),
      (item, testContext) => {
        return !newSubscription || testContext.parent.agreeSubscription;
      }
    ),
    dowellSystemApplication: Yup.number().required(
      t("subscription.serviceType", { ns: "errors" })
    ),
    userCount: Yup.number().required(t("subscription.users", { ns: "errors" })),
    term: Yup.number().required(
      t("subscription.renewalPeriod", { ns: "errors" })
    ),
    firstName: Yup.string().required(
      t("registration.required_FirstName", { ns: "errors" })
    ),
    lastName: Yup.string().required(
      t("registration.required_LastName", { ns: "errors" })
    ),
    email: Yup.string()
      .email(t("registration.invalid_Email", { ns: "errors" }))
      .required(t("registration.required_Email", { ns: "errors" })),
    addressLine1: Yup.string().required(
      t("registration.required_address", { ns: "errors" })
    ),
    city: Yup.string().required(
      t("registration.required_city", { ns: "errors" })
    ),
    state: Yup.string().required(
      t("registration.required_state", { ns: "errors" })
    ),
    postalCode: Yup.string().required(
      t("registration.required_postalcode", { ns: "errors" })
    ),
    country: Yup.string().required(
      t("registration.required_country", { ns: "errors" })
    ),
    billingPhone: Yup.string().required(
      t("registration.required_PhoneNumber", { ns: "errors" })
    ),
    creditCardNumber: Yup.string()
      .required(t("subscription.creditCardRequired", { ns: "errors" }))
      .test(
        "test-card-number",
        t("subscription.creditCardInvalid", { ns: "errors" }),
        (creditCardNumber) => {
          return creditCardValidationLuhnCheck(creditCardNumber ?? "");
        }
      )
      .matches(
        RegexConstants.creditCardValidation,
        t("subscription.creditCardInvalid", { ns: "errors" })
      )
      .nullable(),
    expirationMonth: Yup.string()
      .required(t("subscription.expirationMonthRequired", { ns: "errors" }))
      .test(
        "validate-expiry-month",
        t("subscription.expirationMonthInvalid", { ns: "errors" }),
        (item, testContext) => {
          return expirationDateCheck(
            testContext.parent.expirationYear,
            testContext.parent.expirationMonth
          );
        }
      )
      .nullable(),
    expirationYear: Yup.string()
      .required(t("subscription.expirationYearRequired", { ns: "errors" }))
      .test(
        "validate-expiry-year",
        t("subscription.expirationYearInvalid", { ns: "errors" }),
        (item, testContext) => {
          return expirationDateCheck(
            testContext.parent.expirationYear,
            testContext.parent.expirationMonth
          );
        }
      )
      .nullable(),
    securityCode: Yup.string()
      .required(t("subscription.cvvRequired", { ns: "errors" }))
      .matches(
        RegexConstants.creditCardCVVValidation,
        t("subscription.cvvInvalid", { ns: "errors" })
      )
      .nullable(),
  });
  if (loadingInitial)
    return <LoadingComponent content={t("loading", { ns: "subscription" })} />;

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <SubscriptionMobileMenu
              isCancelled={clientSubscriptionStatus.isCancelled}
            />
            <SubscriptionContent
              isCancelled={clientSubscriptionStatus.isCancelled}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <PageHeader
        header={t("subscription", { ns: "common" })}
        type={"h1"}
        divider={true}
        getAlerts={true}
        addTitle={true}
      />
      <Container className="page">
        <Formik
          initialValues={clientSubscriptions}
          enableReinitialize
          onSubmit={(values, { setErrors, setSubmitting }) =>
            handleFormSubmit(values, setErrors, setSubmitting)
          }
          validationSchema={validationSchema}
        >
          {({
            handleSubmit,
            isSubmitting,
            errors,
            isValid,
            dirty,
            values,
            touched,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form
              className="ui form error"
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column>
                    <Message info>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column>
                            {`${t("pleaseEnter", { ns: "subscription" })} `}
                            <strong>
                              {t("billingAddress", { ns: "common" })}
                            </strong>
                            {` ${t("creditCardInformation", {
                              ns: "subscription",
                            })}. `}
                            <Button
                              type={"button"}
                              floated="right"
                              size="tiny"
                              icon={readMoar ? "minus" : "plus"}
                              content={t(readMoar ? "readLess" : "readMore", {
                                ns: "subscription",
                              })}
                              onClick={() => setReadMoar(!readMoar)}
                            ></Button>
                          </Grid.Column>
                        </Grid.Row>
                        {readMoar && (
                          <Grid.Row>
                            <Grid.Column>
                              <>
                                <i>
                                  <strong>
                                    {`${t("PLEASENOTE", {
                                      ns: "subscription",
                                    })}: `}
                                  </strong>
                                </i>
                                {`${t("noteAddress", {
                                  ns: "subscription",
                                })}.`}
                                <br />
                                <br />
                                {`${t("changeAddress", {
                                  ns: "subscription",
                                })} `}
                                <strong>{`${t("willNot", {
                                  ns: "subscription",
                                })}`}</strong>
                                {` ${t("addressInvoice", {
                                  ns: "subscription",
                                })}.`}
                              </>
                            </Grid.Column>
                          </Grid.Row>
                        )}
                      </Grid>
                    </Message>
                    <ErrorMessage
                      name="error"
                      render={() => <ValidationErrors errors={errors.error} />}
                    />
                  </Grid.Column>
                </Grid.Row>
                {newSubscription && (
                  <SubscriptionOptions
                    minimumUsers={clientSubscriptionStatus.activeUsers}
                  ></SubscriptionOptions>
                )}
                <BillingAddressForm></BillingAddressForm>
                <CreditCard showCreditCardFields={true}
                ></CreditCard>
                {newSubscription && (
                  <>
                    <Grid.Row>
                      <Grid.Column>
                        <Form.Field
                          error={touched.agreeTerms && !!errors.agreeTerms}
                        >
                          <Checkbox
                            type="checkbox"
                            checked={values.agreeTerms}
                            value={values.agreeTerms ? 1 : 0}
                            name="agreeTerms"
                            onChange={() => {
                              setFieldTouched("agreeTerms", true);
                              setFieldValue("agreeTerms", !values.agreeTerms);
                            }}
                            label={
                              <label
                                className={"myLabel"}
                                htmlFor={"agreeTerms"}
                              >
                                {`${t("iagree", {
                                  ns: "subscription",
                                })} `}
                                <Link to="/terms">
                                  {t("terms_header", { ns: "terms" })}
                                </Link>
                                {` ${t("and", {
                                  ns: "common",
                                })} `}
                                <Link to="/eula">
                                  {t("eula", { ns: "common" })}
                                </Link>
                                .
                              </label>
                            }
                          />
                          {touched.agreeTerms && !!errors.agreeTerms && (
                            <Label basic color="red">
                              {errors.agreeTerms}
                            </Label>
                          )}
                        </Form.Field>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <MyCheckbox
                          name={"agreeSubscription"}
                          disabled={false}
                          label={`${t("autoRenewTerm", {
                            ns: "subscription",
                          })}.`}
                        ></MyCheckbox>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                )}
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      disabled={!isValid || !dirty || isSubmitting}
                      loading={isSubmitting}
                      className="save_button modal-button-color"
                      positive
                      content={
                        newSubscription
                          ? t("signup", { ns: "common" })
                          : t("update", { ns: "common" })
                      }
                      type="submit"
                    />
                    <Button
                      className="save_button"
                      as={Link}
                      to="/subscription/status"
                      icon="cancel"
                      type="reset"
                      content={t("cancel", { ns: "common" })}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4} centered>
                  <Grid.Column>
                    <a
                      href="https://verify.authorize.net/anetseal/?pid=04dbc10d-46f3-41e1-80cb-6a3c1737e082"
                      onClick={() => {
                        window.open(
                          "https://verify.authorize.net/anetseal/?pid=04dbc10d-46f3-41e1-80cb-6a3c1737e082",
                          "AuthorizeNetVerification",
                          "width=600,height=430,dependent=yes,resizable=yes,scrollbars=yes,menubar=no,toolbar=no,status=no,directories=no,location=yes"
                        );
                        return false;
                      }}
                      className="text-center"
                      target="_blank"
                      title="http://www.authorize.net/"
                    >
                      <img
                        src="https://verify.authorize.net/anetseal/images/secure90x72.gif"
                        alt="Authorize.Net Merchant - Click to Verify"
                        width="90"
                        height="72"
                      />
                    </a>
                  </Grid.Column>
                  <Grid.Column>
                    <img alt="" src={sslBadgeGif} />
                  </Grid.Column>

                  <Grid.Column>
                    <img alt="" src={creditCardLogos} />
                  </Grid.Column>

                  <Grid.Column>
                    <img alt="" src={sslBadgePng} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
});
