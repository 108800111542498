import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../../api/agent";
import { Pagination, PagingParams } from "../../../models/pagination";
import { AuthorizeNetReport } from "../../../models/systemAdmin";
import { sortingStrings } from "../../../common/util/functions";

export default class AuthorizeNetReportStore {
  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.predicate.keys(),
      () => {
        this.pagingParams = new PagingParams();
        this.authorizeNetRegistry.clear();
      }
    );
  }

  loadingInitial = false;

  predicate = new Map().set("all", true);
  pagination: Pagination | null = null;
  pagingParams = new PagingParams(1, 25);

  resetAllPredicates = () => {
    this.predicate.clear();
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  resetPredicate = (keyToDelete: string) => {
    this.predicate.delete(keyToDelete);
  };

  setPredicate = (
    predicate: string,
    value:
      | string
      | number
      | boolean
      | Date
      | (string | number | boolean | Date)[]
      | undefined
  ) => {
    this.predicate.clear();
    if (value) this.predicate.set(predicate, value);
  };

  clearPredicate = () => {
    this.predicate.clear();
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = new PagingParams(pagingParams.pageNumber, 25);
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "startDate" || key === "endDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  authorizeNetRegistry = new Map<string, AuthorizeNetReport>();

  loadAuthorizeNetReport = async (date: Date) => {
    try {
      this.authorizeNetRegistry.clear();
      const result = await agent.SystemAdminReports.getAuthorizeNetReport(date);
      runInAction(() => {
        result.map((x) => {
          this.setAuthorizeNetReport(x);
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  private setAuthorizeNetReport = (authorizeNetReport: AuthorizeNetReport) => {
    if (authorizeNetReport.reportDate)
      authorizeNetReport.reportDate = new Date(authorizeNetReport.reportDate);

    this.authorizeNetRegistry.set(
      authorizeNetReport.transactionId ?? "",
      authorizeNetReport
    );
  };

  get getTransactions() {
    return Array.from(this.authorizeNetRegistry.values());
  }

  get getApprovedTransactions() {
    return Array.from(this.authorizeNetRegistry.values()).filter(
      (x) => x.status == "settledSuccessfully"
    );
  }

  get getDeclinedTransactions() {
    return Array.from(this.authorizeNetRegistry.values()).filter(
      (x) => x.status == "declined"
    );
  }

  get getVisaMcTransactions() {
    return Array.from(this.authorizeNetRegistry.values()).filter((x) => {
      return (
        (x.cardType == "Visa" || x.cardType == "MasterCard") &&
        x.status == "settledSuccessfully"
      );
    });
  }

  get getAmexTransactions() {
    return Array.from(this.authorizeNetRegistry.values()).filter((x) => {
      return (
        x.cardType == "AmericanExpress" && x.status == "settledSuccessfully"
      );
    });
  }
  get getDiscoverTransactions() {
    return Array.from(this.authorizeNetRegistry.values()).filter((x) => {
      return x.cardType == "Discover" && x.status == "settledSuccessfully";
    });
  }

  get getTransactionsGroupByStatus() {
    return Object.entries(
      Array.from(this.authorizeNetRegistry.values()).reduce(
        (groups, authNet) => {
          groups[authNet.status ?? ""] = groups[authNet.status ?? ""]
            ? [...groups[authNet.status ?? ""], authNet]
            : [authNet];
          return groups;
        },
        {} as { [key: string]: AuthorizeNetReport[] }
      )
    );
  }

  get getTransactionsGroupByCardType() {
    return Object.entries(
      Array.from(this.authorizeNetRegistry.values()).reduce(
        (groups, authNet) => {
          groups[authNet.cardType ?? ""] = groups[authNet.cardType ?? ""]
            ? [...groups[authNet.cardType ?? ""], authNet]
            : [authNet];
          return groups;
        },
        {} as { [key: string]: AuthorizeNetReport[] }
      )
    );
  }

  calculateTotals = (data: AuthorizeNetReport[]) => {
    const summary = data.reduce(
      (accumulator: AuthorizeNetReport, item: AuthorizeNetReport) => {
        accumulator.amount += item.amount ?? 0;
        return accumulator;
      },
      {
        amount: 0,
      }
    );
    return summary;
  };
}
