import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../api/agent";
import { TrialActivityReport } from "../../models/systemAdmin";

export default class TrialActivityStore {
  constructor() {
    makeAutoObservable(this);
    reaction(
      () => {},
      () => {
      }
    );
  }

  loadingInitial = false;

  TrialActivityReportRegistry = new Map<number, TrialActivityReport>();

  loadTrialActivityReport = async () => {
    this.TrialActivityReportRegistry.clear();
    try {
       const result = await agent.SystemAdminReports.getTrialActivityReport(
       );
       runInAction(() => {
        result.forEach((x) => {
          this.setTrialActivityReport(x);
        })
       });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  private setTrialActivityReport = (x: TrialActivityReport) => {
    this.TrialActivityReportRegistry.set(
      x.daysLeft,
      x
    );
  }

  get getTrialActivityReport(): TrialActivityReport[] {
    return Array.from(this.TrialActivityReportRegistry.values()).sort((a, b) => {
      return a.daysLeft - b.daysLeft;
    })
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };
}
