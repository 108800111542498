import { observer } from "mobx-react-lite";
import { Button, Message, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import { useEffect } from "react";
import { PagingParams } from "../../../app/models/pagination";
import { format } from "date-fns";
import MyPaging from "../../../app/common/form/MyPaging";

interface Props {
  clientId: string;
}

export default observer(function DeletedTicketSession({ clientId }: Props) {
  const { t } = useTranslation(["common", "companySettings"]);
  const { ticketSessionDataRecoveryStore } = useStore();

  const {
    getDeletedTicketSessions,
    restoreTicketSession,
    pagingParams,
    pagination,
    setPagingParams,
    DeletedTicketSessionsRegistry,
    loadDeletedTicketSessions,
    setPredicate
  } = ticketSessionDataRecoveryStore;

  useEffect(() => {

      setPredicate("ClientId", clientId);
      loadDeletedTicketSessions(clientId);
  }, [loadDeletedTicketSessions, getDeletedTicketSessions.length, clientId]);

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
      DeletedTicketSessionsRegistry.clear();
    }
  }
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Ticket Date</Table.HeaderCell>
          <Table.HeaderCell>Client Id</Table.HeaderCell>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.HeaderCell>Ticket Number</Table.HeaderCell>
          <Table.HeaderCell>Po Number</Table.HeaderCell>
          <Table.HeaderCell>Customer Name</Table.HeaderCell>
          <Table.HeaderCell>Vehicle</Table.HeaderCell>
          <Table.HeaderCell>Deleted Date</Table.HeaderCell>
          <Table.HeaderCell>Restore</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {getDeletedTicketSessions && getDeletedTicketSessions.length > 0 ? (
          getDeletedTicketSessions.map((x) => (
            <Table.Row key={`ticket.${x.id}`}>
              <Table.Cell>{format(x.date, "P p")}</Table.Cell>
              <Table.Cell>{x.clientId}</Table.Cell>
              <Table.Cell>{x.type}</Table.Cell>
              <Table.Cell>{x.ticketNumber}</Table.Cell>
              <Table.Cell>{x.poNumber}</Table.Cell>
              <Table.Cell>{x.customerName}</Table.Cell>
              <Table.Cell>{x.vehicleDescription}</Table.Cell>
              <Table.Cell>{format(x.deletedDate, "P p")}</Table.Cell>
              <Table.Cell>
                {" "}
                <Button
                  className="save_button"
                  icon="check"
                  content="Restore"
                  type="submit"
                  onClick={() => restoreTicketSession(x.id)}
                />
              </Table.Cell>
            </Table.Row>
          ))
        ) : (
          <Table.Row key={"noItems"}>
            <Table.Cell width="8" colSpan={8}>
              <Message
                color="yellow"
                className="textAlignCenter fontStyleItalic"
              >
                {t("grid_noItems", { ns: "common" })}
              </Message>
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="8">
            <MyPaging
              itemCount={pagination ? pagination.totalItems : 25}
              pageSize={pagingParams.pageSize}
              currentPage={pagination ? pagination.currentPage : 1}
              onClick={listOnClick}
              onChange={listOnClick}
              floated="right"
            ></MyPaging>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
});
