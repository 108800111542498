import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import {
  QuickVinLookup,
  SettingsQuickVin,
  SettingsQuickVinFormValues,
} from "../models/quickVin";
import { Vehicle } from "../models/vehicle";

export default class QuickVinStore {
  loadingInitial = false;
  activeTab: number = 0;

  selectedSettingsQuickVin: SettingsQuickVinFormValues = {
    id: "",
    isActive: false,
    businessName: "",
    contactName: "",
    contactEmail: "",
    url: "",
    addressLine1: "",
    city: "",
    state: "",
    postalCode: "",
    businessPhoneId: "",
  };

  quickVinLookup: QuickVinLookup = {
    tag: undefined,
    state: undefined,
    vin: undefined,
    result: undefined,
  };

  lookupRegistry = new Map<number, Vehicle>();

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => {},
      () => {
        this.lookupRegistry.clear();
      }
    );
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  setActiveTab = (activeTab: string | number | undefined) => {
    this.activeTab = activeTab ? Number(activeTab) : 0;
  };

  loadSettingsQuickVin = async () => {
    this.loadingInitial = true;
    try {
      const result = await agent.QuickVin.getSettingsQuickVin();
      runInAction(() => {
        this.selectedSettingsQuickVin = result;
      });
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  setSelectedSettingsQuickVin = (values: SettingsQuickVinFormValues) => {
    this.selectedSettingsQuickVin = values;
  };

  createSettingsQuickVin = async (values: SettingsQuickVinFormValues) => {
    try {
      let myNew: SettingsQuickVin = new SettingsQuickVin(values);
      await agent.QuickVin.addSettingsQuickVin(myNew);

      runInAction(() => {
        this.setSelectedSettingsQuickVin(values);
      });
    } catch (error) {
      console.log(error);
    }
  };

  updateSettingsQuickVin = async (values: SettingsQuickVinFormValues) => {
    try {
      let myNew: SettingsQuickVin = new SettingsQuickVin(values);
      await agent.QuickVin.updateSettingsQuickVin(myNew);

      runInAction(() => {
        this.setSelectedSettingsQuickVin(values);
      });
    } catch (error) {
      console.log(error);
    }
  };

  resetLookupRegistry = () => {
    this.lookupRegistry.clear();
  };

  lookUpVehicle = async (quickVinLookup: QuickVinLookup) => {
    this.loadingInitial = true;
    try {
      this.lookupRegistry.clear();
      const result = await agent.QuickVin.lookUpVehicle(quickVinLookup);
      runInAction(() => {
        result.forEach((vehicle) => {
          this.setLookupResults(vehicle);
          if (!this.quickVinLookup.vin && vehicle.vin)
            this.setQuickVinLookupVin(vehicle.vin);
        });
      });
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      this.setLoadingInitial(false);
    }
  };

  setQuickVinLookupVin(vin: string) {
    this.quickVinLookup.vin = vin;
  }

  resetQuickVin = () => {
    this.quickVinLookup = {
      tag: undefined,
      state: undefined,
      vin: undefined,
      result: undefined,
    };
  };

  private setLookupResults = (values: Vehicle) => {
    this.lookupRegistry.set(this.lookupRegistry.size, values);
  };

  get getVehicles() {
    return Array.from(this.lookupRegistry.values()).sort((a, b) => {
      return (a.year ?? 0) - (b.year ?? 0);
    });
  }

  getVehicle = (id: number) => {
    return this.lookupRegistry.get(id);
  };

  isVehicleComplete = (values: Vehicle): boolean => {
    let returnValue: boolean = false;

    if (values.year && values.makeId && values.modelId && values.engineId)
      returnValue = true;

    return returnValue;
  };
}
