import React, { useEffect } from "react";
import {
  Grid,
  Image,
  Button,
  Table,
  Icon,
  Divider,
  Dropdown,
  Message,
  DropdownItemProps,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import adjustmentsdiagnosis from "../../../app/common/img/adjustmentsdiagnosis.png";
import basicmaintenance from "../../../app/common/img/basicmaintenance.png";
import brakeswheels from "../../../app/common/img/brakeswheels.png";
import computerselectronics from "../../../app/common/img/computerselectronics.png";
import coolingsystembelts from "../../../app/common/img/coolingsystembelts.png";
import drivetrainrearend from "../../../app/common/img/drivetrainrearend.png";
import electriclights from "../../../app/common/img/electricligths.png";
import enginevalvetrain from "../../../app/common/img/enginevalvetrain.png";
import exhaustemissions from "../../../app/common/img/exhaustemissions.png";
import frontendsuspension from "../../../app/common/img/frontendsuspension.png";
import fuelsystemtuneup from "../../../app/common/img/fuelsystemtuneup.png";
import gasketsseals from "../../../app/common/img/gasketseals.png";
import heatingac from "../../../app/common/img/heatingac.png";
import miscellaneousaccessories from "../../../app/common/img/miscellaneousaccessories.png";
import steeingcolumngauges from "../../../app/common/img/steeringcolumngauges.png";
import transmissiontransaxle from "../../../app/common/img/transmissiontransaxle.png";
import PageHeader from "../../../app/layout/PageHeader";
import { observer } from "mobx-react-lite";
import Sidebar from "./Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../app/stores/store";
import { format } from "date-fns";
import { JobCategory, TicketWorkflowSteps } from "../../../app/models/enums";
import TicketBackButton from "./TicketBackButton";
import { JobLabor } from "../../../app/models/jobLabor";
import { Job } from "../../../app/models/job";
import { v4 as uuid } from "uuid";
import { PackageJobs } from "../../../app/models/packageJobs";
import { MiscellaneousCharge } from "../../../app/models/miscellaneousCharges";
import { VehicleFormValues } from "../../../app/models/vehicle";
import { CustomerFormValues } from "../../../app/models/customer";
import { TicketSession } from "../../../app/models/ticket";
import PotentialCustomerMatchingMessage from "../../customers/PotentialCustomerMatchingMessage";
import SummaryButton from "./SummaryButton";

interface Props {
  autoCapitalizeFirstLetter: boolean;
  miscellaneousChargeItems: MiscellaneousCharge[];
}

export default observer(function JobCategoryForm({
  autoCapitalizeFirstLetter,
  miscellaneousChargeItems,
}: Props) {
  const { t } = useTranslation(["common", "translation", "recentTickets"]);

  const navigate = useNavigate();

  const {
    vehicleStore,
    ticketSessionStore,
    modalStore,
    packageJobStore,
    jobLaborStore,
    customerStore,
    ticketStore
  } = useStore();

  const {
    selectedTicketSession,
    updateTicketSession,
    calculateTotals,
    setSelectedJobLabor,
    addJobLabor,
    loadTicketSession,
    setSelectedTicketSession,
    setSelectedPONumber,
    setBatchTicketItems,
    setBatchTicketPaymentItems,
    resetTicketPaymentItems,
    populateJobLabors,
    populateLaborMap,
    setAlwaysMiscChargesToTicketItems,
  } = ticketSessionStore;

  const { selectedVehicle } = vehicleStore;
  const { selectedCustomer } = customerStore;
  const { jobSearch, jobList, loadDsiJobList } = jobLaborStore;
  const { selectedSettingsTicketFormatting, loadSettingsTicketFormatting } =
  ticketStore;

  const { loadPackageJobs, getPackageJobs, addPackageJobToTicketSession } =
    packageJobStore;

  const { setSelectedVehicle } = vehicleStore;
  const { setSelectedCustomer } = customerStore;

  useEffect(() => {
    loadPackageJobs();
  }, [loadPackageJobs]);
  useEffect(() => {
    loadSettingsTicketFormatting();
  }, [loadSettingsTicketFormatting]);

  const { id } = useParams<{ id: string }>();
  const { complete } = useParams<{ complete: string }>();

  function handleAddPackageJob(packageJob: PackageJobs) {
    addPackageJobToTicketSession(packageJob.id ?? "", id ?? "")
      .then(() => {
        if (id) {
          loadTicketSession(
            id,
            TicketWorkflowSteps.JobCategory
          )
            .then((result) => {
              if (result) {
                setSelectedVehicle(new VehicleFormValues(result.vehicle));
                setSelectedCustomer(new CustomerFormValues(result.customer));
                setSelectedPONumber(result.poNumber);

                let ticketSession = new TicketSession(result);
                setSelectedTicketSession(ticketSession);

                if (result.jobLabors && result.jobLabors.length > 0) {
                  populateJobLabors(result.jobLabors);
                  let jobLabor: JobLabor | undefined = Array.from(
                    result.jobLabors.values()
                  ).find((x) => {
                    return x.isComplete === false;
                  });
                  if (jobLabor) {
                    setSelectedJobLabor(jobLabor);
                    if (
                      jobLabor.job?.jobTypeId &&
                      result.vehicle &&
                      result.vehicle.modelId &&
                      result.vehicle.engineId
                    ) {
                      loadDsiJobList(
                        jobLabor.job.jobTypeId,
                        result.vehicle.modelId,
                        result.vehicle.engineId,
                        result.vehicle.isCustom
                      );
                    }
                  } else {
                    let jobLabor = new JobLabor();
                    jobLabor.id = uuid();
                    setSelectedJobLabor(jobLabor);
                    addJobLabor(jobLabor);
                  }
                }

                if (result.ticketItems && result.ticketItems.length > 0) {
                  setBatchTicketItems(result.ticketItems, selectedSettingsTicketFormatting.showRemoveAndReplace );
                }

                if (
                  miscellaneousChargeItems &&
                  miscellaneousChargeItems.length > 0
                ) {
                  setAlwaysMiscChargesToTicketItems(
                    miscellaneousChargeItems
                  );
                }

                if (result.ticketPayments && result.ticketItems.length > 0) {
                  setBatchTicketPaymentItems(result.ticketPayments);
                } else {
                  resetTicketPaymentItems();
                }

                if (complete === "false") {
                  calculateTotals();
                }

                if (result.labors && result.labors.length > 0) {
                  populateLaborMap(result.labors);
                }
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => navigate(`/ticket/summary/${id}/${complete}`));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleJobCategory(jobCategory: JobCategory) {
    let jobLabor: JobLabor = new JobLabor();
    jobLabor.id = uuid();
    jobLabor.job = new Job();
    jobLabor.job.jobCategoryId = jobCategory;
    jobLabor.isComplete = false;

    setSelectedJobLabor(jobLabor);
    addJobLabor(jobLabor);

    if (id && jobLabor) {
      updateTicketSession(
        TicketWorkflowSteps.JobCategory,
        id ?? "",
        selectedVehicle,
        selectedCustomer
      ).then(() => {
        navigate(`/ticket/jobType/${id}/${complete}`);
      });
    }
  }

  useEffect(() => {
    if (
      selectedVehicle.modelId &&
      selectedVehicle.engineId &&
      !selectedVehicle.isCustom
    ) {
      jobSearch(selectedVehicle.modelId, selectedVehicle.engineId);
    }
  }, [
    jobSearch,
    selectedVehicle.modelId,
    selectedVehicle.engineId,
    selectedVehicle.isCustom,
  ]);

  return (
    <Grid stackable>
      <Grid.Column width={11} className="jobCategoryContainer">
        <PotentialCustomerMatchingMessage
          workflowStep={TicketWorkflowSteps.Finalize}
        />
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column>
              <PageHeader
                type="h1"
                header={t("category", { ns: "tickets" })}
                className="modal-text-color"
                textAlign="left"
                divider={false}
                addTitle={true}
              />
            </Grid.Column>
            <Grid.Column>
              <SummaryButton
                id={id ?? ""}
                selectedVehicle={selectedVehicle}
                selectedCustomer={selectedCustomer}
                completed={complete ?? "false"}
                alwaysMiscellaneousChargeItems={miscellaneousChargeItems}
              />
              <Button
                floated="right"
                type="button"
                onClick={() => {
                  modalStore.openModal(
                    <TicketBackButton
                      backUrl={`/ticket/vehicle/${id}/${complete}`}
                    />
                  );
                }}
              >
                <Icon name="caret left" />
                {t("back", { ns: "tickets" })}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider />
        <Grid columns={4} centered className="maxWidth730">
          <Grid.Row className="paddingLeftRight alignItemsCenter fourToTwo">
            <Grid.Column className="padding8 width78">
              <Button
                className="no_padding_image_buttons"
                onClick={() => {
                  handleJobCategory(JobCategory.AdjustmentsDiagnosis);
                }}
              >
                <Image src={adjustmentsdiagnosis} />
              </Button>
            </Grid.Column>
            <Grid.Column className="padding8 width78">
              <Button
                className="no_padding_image_buttons"
                onClick={() => {
                  handleJobCategory(JobCategory.ComputersElectronics);
                }}
              >
                <Image src={computerselectronics} />
              </Button>
            </Grid.Column>
            <Grid.Column className="padding8 width78">
              <Button
                className="no_padding_image_buttons"
                onClick={() => {
                  handleJobCategory(JobCategory.ExhaustEmissions);
                }}
              >
                <Image src={exhaustemissions} />
              </Button>
            </Grid.Column>
            <Grid.Column className="padding8 width78">
              <Button
                className="no_padding_image_buttons"
                onClick={() => {
                  handleJobCategory(JobCategory.HeatingAC);
                }}
              >
                <Image src={heatingac} />
              </Button>
            </Grid.Column>
            <Grid.Column className="padding8 width78">
              <Button
                className="no_padding_image_buttons"
                onClick={() => {
                  handleJobCategory(JobCategory.BasicMaintenance);
                }}
              >
                <Image src={basicmaintenance} />
              </Button>
            </Grid.Column>
            <Grid.Column className="padding8 width78">
              <Button
                className="no_padding_image_buttons"
                onClick={() => {
                  handleJobCategory(JobCategory.DriveTrainRearEnd);
                }}
              >
                <Image src={drivetrainrearend} />
              </Button>
            </Grid.Column>
            <Grid.Column className="padding8 width78">
              <Button
                className="no_padding_image_buttons"
                onClick={() => {
                  handleJobCategory(JobCategory.FrontEndSuspension);
                }}
              >
                <Image src={frontendsuspension} />
              </Button>
            </Grid.Column>
            <Grid.Column className="padding8 width78">
              <Button
                className="no_padding_image_buttons"
                onClick={() => {
                  handleJobCategory(JobCategory.MiscellaneousAccessories);
                }}
              >
                <Image src={miscellaneousaccessories} />
              </Button>
            </Grid.Column>
            <Grid.Column className="padding8 width78">
              <Button
                className="no_padding_image_buttons"
                onClick={() => {
                  handleJobCategory(JobCategory.BrakesWheels);
                }}
              >
                <Image src={brakeswheels} />
              </Button>
            </Grid.Column>
            <Grid.Column className="padding8 width78">
              <Button
                className="no_padding_image_buttons"
                onClick={() => {
                  handleJobCategory(JobCategory.ElectricalLights);
                }}
              >
                <Image src={electriclights} />
              </Button>
            </Grid.Column>
            <Grid.Column className="padding8 width78">
              <Button
                className="no_padding_image_buttons"
                onClick={() => {
                  handleJobCategory(JobCategory.FuelSystemTuneup);
                }}
              >
                <Image src={fuelsystemtuneup} />
              </Button>
            </Grid.Column>
            <Grid.Column className="padding8 width78">
              <Button
                className="no_padding_image_buttons"
                onClick={() => {
                  handleJobCategory(JobCategory.SteeringColumnGauges);
                }}
              >
                <Image src={steeingcolumngauges} />
              </Button>
            </Grid.Column>
            <Grid.Column className="padding8 width78">
              <Button
                className="no_padding_image_buttons"
                onClick={() => {
                  handleJobCategory(JobCategory.CoolingSystemBelts);
                }}
              >
                <Image src={coolingsystembelts} />
              </Button>
            </Grid.Column>
            <Grid.Column className="padding8 width78">
              <Button
                className="no_padding_image_buttons"
                onClick={() => {
                  handleJobCategory(JobCategory.EngineValveTrain);
                }}
              >
                <Image src={enginevalvetrain} />
              </Button>
            </Grid.Column>
            <Grid.Column className="padding8 width78">
              <Button
                className="no_padding_image_buttons"
                onClick={() => {
                  handleJobCategory(JobCategory.GasketsSeals);
                }}
              >
                <Image src={gasketsseals} />
              </Button>
            </Grid.Column>
            <Grid.Column className="padding8 width78">
              <Button
                className="no_padding_image_buttons"
                onClick={() => {
                  handleJobCategory(JobCategory.TransmissionTransaxle);
                }}
              >
                <Image src={transmissiontransaxle} />
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              className="alignItemsCenter"
              textAlign="center"
              width={11}
            >
              <Dropdown
                name="jobSearch"
                clearable
                icon={"search"}
                options={jobList}
                search
                selection
                multiple={false}
                selectOnNavigation={false}
                selectOnBlur={false}
                onChange={(e, d) => {
                  if (d.value && e.nativeEvent.type === "click") {
                    let jobLabor: JobLabor = new JobLabor();
                    jobLabor.id = uuid();
                    jobLabor.job = new Job();
                    jobLabor.job.jobId = Number(d.value);
                    let job = jobList.find((x) => {
                      return x.value === d.value;
                    });
                    jobLabor.job.job = job ? job.text?.toString() : "";

                    addJobLabor(jobLabor);
                    setSelectedJobLabor(jobLabor);

                    if (selectedTicketSession && id)
                      updateTicketSession(
                        TicketWorkflowSteps.Job,
                        id,
                        selectedVehicle,
                        selectedCustomer
                      ).then(() => {
                        navigate(`/ticket/time/${id}/${complete}`);
                      });
                  }
                }}
                onBlur={() => {}}
                placeholder={t("job_search", { ns: "tickets" }) + ":"}
                className="half-width"
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered></Grid.Row>
          <Grid.Row columns={1}>
            <PageHeader
              type="h3"
              header={t("select_package_job", { ns: "tickets" }) + ":"}
              divider={false}
              addTitle={false}
            />
            <Grid.Column className="noPaddingLeft">
              <Table striped unstackable className="dnxTable">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>
                      {t("name", { ns: "tickets" })}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t("items", { ns: "tickets" })}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t("created", { ns: "tickets" })}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {getPackageJobs && getPackageJobs.length > 0 ? (
                    getPackageJobs.map((packageJobs, index) => (
                      <Table.Row key={`packageJob.${packageJobs.id}`}>
                        <Table.Cell className="textAlignLeft">
                          <Button
                            color="green"
                            icon="plus"
                            type="button"
                            onClick={() => {
                              if (packageJobs.id)
                                handleAddPackageJob(packageJobs);
                            }}
                          ></Button>
                        </Table.Cell>
                        <Table.Cell
                          data-label={`${t("name", { ns: "tickets" })}:`}
                        >
                          {packageJobs.name}
                        </Table.Cell>
                        <Table.Cell
                          data-label={`${t("items", { ns: "tickets" })}:`}
                        >
                          {packageJobs.description}
                        </Table.Cell>
                        <Table.Cell
                          data-label={`${t("created", { ns: "tickets" })}:`}
                        >
                          {format(
                            packageJobs.createdDate
                              ? packageJobs.createdDate
                              : new Date(),
                            "PP"
                          )}
                        </Table.Cell>
                      </Table.Row>
                    ))
                  ) : (
                    <Table.Row key={"noItems"}>
                      <Table.Cell width="5" colSpan={5}>
                        <Message
                          color="yellow"
                          className="textAlignCenter fontStyleItalic"
                        >
                          {t("grid_noItems", { ns: "common" })}
                        </Message>
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
      <Grid.Column width={5}>
        <Grid>
          <Grid.Row>
            <Grid.Column></Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Sidebar
              workflowStep={TicketWorkflowSteps.JobCategory}
              autoCapitalizeFirstLetter={autoCapitalizeFirstLetter}
            />
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid>
  );
});
