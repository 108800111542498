import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { Pagination, PagingParams } from "../../models/pagination";
import { DeletedTicketSessions } from "../../models/systemAdmin";

export default class TicketSessionDataRecoveryStore {
  constructor() {
    makeAutoObservable(this);
  }

  loadingInitial = false;

  predicate = new Map().set("all", true);
  pagination: Pagination | null = null;
  pagingParams = new PagingParams(1, 25);

  resetAllPredicates = () => {
    this.predicate.clear();
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  resetPredicate = (keyToDelete: string) => {
    this.predicate.delete(keyToDelete);
  };

  setPredicate = (
    predicate: string,
    value:
      | string
      | number
      | boolean
      | Date
      | (string | number | boolean | Date)[]
      | undefined
  ) => {
    this.predicate.clear();
    if (value) this.predicate.set(predicate, value);
  };

  clearPredicate = () => {
    this.predicate.clear();
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = new PagingParams(pagingParams.pageNumber, 25);
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "startDate" || key === "endDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }
  DeletedTicketSessionsRegistry = new Map<string, DeletedTicketSessions>();

  restoreTicketSession = async (id: string) => {
    try {
      this.DeletedTicketSessionsRegistry.clear();
      await agent.SystemAdminTools.restoreTicketSession(id);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  loadDeletedTicketSessions = async (id: string) => {
    try {
      const result = await agent.SystemAdminTools.getDeletedTicketSessions(
        this.axiosParams
      );
      runInAction(() => {
        result.data.forEach((x) => {
          this.setDeletedTicketSessions(x);
        });
        this.setPagination(result.pagination);
      });
    } catch (error) {
      console.log(error);
    }
  };
  private setDeletedTicketSessions = (deletedSessions: DeletedTicketSessions) => {
    deletedSessions.deletedDate = new Date(deletedSessions.deletedDate);
    deletedSessions.date = new Date(deletedSessions.date);
    this.DeletedTicketSessionsRegistry.set(deletedSessions.id, deletedSessions);
  };

  get getDeletedTicketSessions() {
    return Array.from(this.DeletedTicketSessionsRegistry.values()).sort(
      (a, b) => b.date.getTime() - a.date.getTime()
    );
  }
}
