import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../../api/agent";
import { Pagination, PagingParams } from "../../../models/pagination";
import { StatsOverview } from "../../../models/systemAdmin";

export default class StatsOverviewStore {
  constructor() {
    makeAutoObservable(this);
  }

  loadingInitial = false;

  predicate = new Map().set("all", true);
  pagination: Pagination | null = null;
  pagingParams = new PagingParams(1, 25);

  resetAllPredicates = () => {
    this.predicate.clear();
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  resetPredicate = (keyToDelete: string) => {
    this.predicate.delete(keyToDelete);
  };

  setPredicate = (
    predicate: string,
    value:
      | string
      | number
      | boolean
      | Date
      | (string | number | boolean | Date)[]
      | undefined
  ) => {
    this.predicate.clear();
    if (value) this.predicate.set(predicate, value);
  };

  clearPredicate = () => {
    this.predicate.clear();
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = new PagingParams(pagingParams.pageNumber, 25);
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "startDate" || key === "endDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }
  loading = false;
  statsOverview: StatsOverview | undefined = undefined;
  statsCompare: StatsOverview | undefined = undefined;

  setLoading = (state: boolean) => {
    this.loading = state;
  };

  forceStatsRefresh = async () => {
    try {
      await agent.SystemAdminReports.loadStats();
    } catch (error) {
      console.log(error);
    }
  };

  loadStatsOverview = async () => {
    try {
      this.clearStatsOverview();
      const result = await agent.SystemAdminReports.getStatsOverview();
      runInAction(() => {
        this.setStatsOverview(result);
      });
    } catch (error) {
      console.log(error);
    }
  };

  loadStatsCompare = async () => {
    try {
      const result = await agent.SystemAdminReports.loadStatsCompare(this.axiosParams);
      runInAction(() => {
        this.setStatsCompare(result);
      });
    } catch (error) {
      console.log(error);
    }
  };

  setStatsOverview = (value: StatsOverview | undefined) => {
    if (value) {
      value.lastCronRun = new Date(value.lastCronRun);
    }
    this.statsOverview = value;
  };

  clearStatsOverview = () => {
    this.statsOverview = undefined;
  };

  setStatsCompare = (value: StatsOverview | undefined) => {
    if (value) {
      value.lastCronRun = new Date(value.lastCronRun);
    }
    this.statsCompare = value;
  };

  clearStatsCompare = () => {
    this.statsCompare = undefined;
  };
}
