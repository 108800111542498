import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Menu, Tab } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import ManageUsers from "../Users/ManageUsers";
import Overview from "./Overview";
import Extensions from "./Extensions";
import TrialActivity from "./TrialActivity";
import CompanyUser from "./CompanyUser";
import { t } from "i18next";
import { Link, useNavigate } from "react-router-dom";
import SignUp from "./SignUp";
import Auth from "./Auth";
import Marketing from "./Marketing";

interface Props {
  activeSubTab: number;
}

export default observer(function StatsTabs(props: Props) {
  const { t } = useTranslation(["common", "systemAdmin"]);
  const navigate = useNavigate();

  const { systemAdminStore } = useStore();
  const { setActiveSubTab, activeSubTab } = systemAdminStore;

  const panes = [
    {
      menuItem: {
        as: Link,
        content: t("overview", { ns: "common" }),
        to: "/admin/Stats/Overview",
        exact: true,
      }, 
      render: () => (
        <Tab.Pane active={0 === activeSubTab}>
          <Overview />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        as: Link,
        content: t("signup", { ns: "common" }),
        to: "/admin/Stats/SignUp",
        exact: true,
      },       
      render: () => (
        <Tab.Pane active={1 === activeSubTab}>
          <SignUp />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        as: Link,
        content: t("auth", { ns: "common" }),
        to: "/admin/Stats/Auth",
        exact: true,
      }, 
      render: () => (
        <Tab.Pane active={2 === activeSubTab}>
          <Auth />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        as: Link,
        content: t("trial_activity", { ns: "common" }),
        to: "/admin/Stats/TrialActivity",
        exact: true,
      }, 
      render: () => (
        <Tab.Pane active={3 === activeSubTab}>
          <TrialActivity />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        as: Link,
        content: t("co_users", { ns: "common" }),
        to: "/admin/Stats/CoUsers",
        exact: true,
      }, 
      render: () => (
        <Tab.Pane active={4 === activeSubTab}>
          <CompanyUser />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        as: Link,
        content: t("marketing", { ns: "common" }),
        to: "/admin/Stats/Marketing",
        exact: true,
      }, 
      render: () => (
        <Tab.Pane active={5 === activeSubTab}>
          <Marketing />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        as: Link,
        content: t("extensions", { ns: "common" }),
        to: "/admin/Stats/Extensions",
        exact: true,
      }, 
      render: () => (
        <Tab.Pane active={6 === activeSubTab}>
          <Extensions />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <>
      <Tab
        menu={{ fluid: true }}
        menuPosition="left"
        panes={panes}
        activeIndex={activeSubTab}
        onTabChange={(e, data) => {
          setActiveSubTab(data.activeIndex);
        }}
      />
    </>
  );
});
