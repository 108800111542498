import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import {
  TicketSession,
  AbandonedTickets,
  TicketItem,
  SettingsWorkflow,
  SettingsTicketDefaults,
  SettingsTicketNumber,
  TicketPaymentItem,
  CreateTicketFormValues,
  TicketFinalizeFormValues,
  PONumberFormValues,
} from "../models/ticket";
import { Customer, CustomerFormValues } from "../models/customer";
import { Vehicle, VehicleFormValues } from "../models/vehicle";
import { Pagination, PagingParams } from "../models/pagination";
import {
  JobCategory,
  PaymentStatus,
  PromisedDateOptions,
  SalesTaxOptionType,
  TicketItemType,
  TicketType,
  TicketWorkflowSteps,
  TimeType,
  TransactionType,
} from "../models/enums";
import { v4 as uuid } from "uuid";
import { JobAddOn, JobLabor } from "../models/jobLabor";
import { Labor } from "../models/labor";
import { MiscellaneousCharge } from "../models/miscellaneousCharges";
import {
  getMiscChargeAmount,
  round2Decimals,
  round3Decimals,
} from "../common/util/functions";
import { addDays } from "date-fns";
import { Job } from "../models/job";
import { DropdownItemProps, Item } from "semantic-ui-react";
import { ca, ta } from "date-fns/locale";
import { number } from "yup";

export default class TicketSessionStore {
  loading = false;
  loadingInitial = false;
  activeTab: number = 0;
  pagingParams = new PagingParams();
  pagination: Pagination | null = null;
  predicate = new Map().set("all", true);

  ticketSessionRegistry = new Map<string | undefined, AbandonedTickets>();
  ticketItems = new Map<string | undefined, TicketItem>();
  ticketPaymentItems = new Map<string | undefined, TicketPaymentItem>();
  jobLaborRegistry = new Map<string | undefined, JobLabor>();
  laborRegistry = new Map<string | undefined, Labor>();
  poNumber: PONumberFormValues = {
    value: undefined,
    error: undefined,
  };

  selectedTicketSession?: TicketSession = undefined;
  selectedJobLabor: JobLabor | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.predicate.keys(),
      () => {
        this.pagingParams = new PagingParams();
        this.ticketSessionRegistry.clear();
        this.ticketItems.clear();
        this.ticketPaymentItems.clear();
        this.jobLaborRegistry.clear();
        this.laborRegistry.clear();

        this.loadAbandonedTickets();
      }
    );
  }

  resetTicketSessionRegistries = () => {
    this.ticketSessionRegistry.clear();
    this.ticketItems.clear();
    this.ticketPaymentItems.clear();
    this.jobLaborRegistry.clear();
    this.laborRegistry.clear();
  };

  setActiveTab = (activeTab: string | number | undefined) => {
    this.activeTab = activeTab ? Number(activeTab) : 0;
  };

  get abandonedTicketsByDate() {
    return Array.from(this.ticketSessionRegistry.values()).sort(
      (a, b) => b.accessedDate.getTime() - a.accessedDate.getTime()
    );
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  loadAbandonedTickets = async () => {
    this.loadingInitial = true;
    try {
      this.ticketSessionRegistry.clear();
      const result = await agent.Tickets.listAbandonedTickets(this.axiosParams);
      runInAction(() => {
        result.data.forEach((ticket) => {
          this.setTicketSession(ticket);
        });
        this.setPagination(result.pagination);
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  private setTicketSession = (ticket: AbandonedTickets) => {
    ticket.accessedDate = new Date(ticket.accessedDate);

    this.ticketSessionRegistry.set(ticket.id, ticket);
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = pagingParams;
  };

  resetTicketSessionRegistry = () => {
    this.ticketSessionRegistry.clear();
  };

  resetPredicate = () => {
    this.predicate.clear();
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "startDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  setPredicate = (
    predicate: string,
    value: string | Date | number | undefined
  ) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        if (key !== "startDate") this.predicate.delete(key);
      });
    };
    switch (predicate) {
      case "number":
        resetPredicate();
        if (value) this.predicate.set("number", value);
        break;
      case "staff":
        resetPredicate();
        if (value) this.predicate.set("staff", value);
        break;
      case "vehicleDescription":
        resetPredicate();
        if (value) this.predicate.set("vehicleDescription", value);
        break;
      case "customerName":
        resetPredicate();
        if (value) this.predicate.set("customerName", value);
        break;
      case "poNumber":
        resetPredicate();
        if (value) this.predicate.set("poNumber", value);
        break;
    }
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  removeTicketSession = async (id: string) => {
    try {
      await agent.Tickets.removeTicketSession(id);

      runInAction(() => {
        this.ticketSessionRegistry.delete(id);
        let count = this.ticketSessionRegistry.size;
        if (count < 1) {
          this.pagingParams = new PagingParams();
        }
        this.loadAbandonedTickets();
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  setSelectedTicketSession = (ticketSession: TicketSession) => {
    this.selectedTicketSession = ticketSession;

    this.selectedTicketSession.promisedDate = ticketSession.promisedDate
      ? new Date(ticketSession.promisedDate)
      : undefined;
    this.selectedTicketSession.invoiceDate = ticketSession.invoiceDate
      ? new Date(ticketSession.invoiceDate)
      : new Date();
    this.selectedTicketSession.estimateDate = ticketSession.estimateDate
      ? new Date(ticketSession.estimateDate)
      : new Date();
  };

  setSelectedTicketCustomer = (customer: CustomerFormValues) => {
    if (this.selectedTicketSession)
      this.selectedTicketSession.customer = new Customer(customer);
  };
  setSelectedTicketVehicle = (vehicle: VehicleFormValues) => {
    if (this.selectedTicketSession)
      this.selectedTicketSession.vehicle = new Vehicle(vehicle);
  };

  addTicketPaymentItem = (newItem: TicketPaymentItem) => {
    if (newItem.createdDate)
      newItem.createdDate = new Date(newItem.createdDate);

    newItem.amount = round2Decimals(Number(newItem.amount));
    newItem.refunded = false;
    newItem.refundedAmount = 0.0;

    this.ticketPaymentItems.set(newItem.id, newItem);
  };

  removeTicketPaymentItem = (id: string) => {
    this.ticketPaymentItems.delete(id);
  };

  resetTicketPaymentItems = () => {
    this.ticketPaymentItems.clear();
  };

  setBatchTicketPaymentItems = (ticketItems: TicketPaymentItem[]) => {
    this.ticketPaymentItems.clear();
    ticketItems.forEach((item, index) => {
      if (item.createdDate) item.createdDate = new Date(item.createdDate);
      else item.createdDate = new Date();
      this.ticketPaymentItems.set(item.id, item);
    });
  };

  addTicketItemToSelectedTicketSession = (
    ticketItem: TicketItem,
    miscellaneousCharge: MiscellaneousCharge[],
    salesTaxOptionType: SalesTaxOptionType,
    selectedVehicle?: VehicleFormValues,
    selectedCustomer?: CustomerFormValues
  ) => {
    if (!this.ticketItems)
      this.ticketItems = new Map<string | undefined, TicketItem>();

    let hasParts = this.hasParts();

    if (!ticketItem.id) ticketItem.id = uuid();

    ticketItem.rate =
      ticketItem.type === TicketItemType.Parts
        ? round3Decimals(Number(ticketItem.rate ?? 0.0))
        : round2Decimals(Number(ticketItem.rate ?? 0.0));
    ticketItem.quantity = round2Decimals(Number(ticketItem.quantity ?? 0));
    ticketItem.unitPrice =
      ticketItem.unitPrice ?? round2Decimals(Number(ticketItem.rate ?? 0.0))
        ? round2Decimals(Number(ticketItem.unitPrice))
        : undefined;
    ticketItem.subTotal = round2Decimals(ticketItem.rate * ticketItem.quantity);
    ticketItem.order = ticketItem.order ?? this.ticketItems.size;

    let taxRate: number = 0.0;

    switch (ticketItem.type) {
      case TicketItemType.Parts:
        ticketItem.isTaxable =
          (salesTaxOptionType & SalesTaxOptionType.Parts) ===
          SalesTaxOptionType.Parts;

        taxRate = this.selectedTicketSession?.taxRate ?? 0.0;
        break;
      case TicketItemType.Labor:
        ticketItem.isTaxable =
          (salesTaxOptionType & SalesTaxOptionType.OnTicket) ===
          SalesTaxOptionType.OnTicket
            ? hasParts
            : (salesTaxOptionType & SalesTaxOptionType.Labor) ===
              SalesTaxOptionType.Labor;

        taxRate = this.selectedTicketSession?.taxRate ?? 0.0;
        break;
      default: {
        taxRate = this.selectedTicketSession?.taxRate ?? 0.0;
        break;
      }
    }

    ticketItem.taxRate = taxRate ?? 0.0;
    ticketItem.tax = ticketItem.isTaxable
      ? round2Decimals(
          Number(taxRate) * Number(0.01) * Number(ticketItem.subTotal)
        )
      : 0.0;
    ticketItem.total = Number(ticketItem.subTotal) + Number(ticketItem.tax);

    this.ticketItems.set(ticketItem.id, ticketItem);

    this.calculateMiscellaneousCharges(miscellaneousCharge);

    this.calculateTotals();
    this.updateTicketSession(
      TicketWorkflowSteps.Summary,
      this.selectedTicketSession?.id ?? "",
      selectedVehicle,
      selectedCustomer
    );
  };

  updateIsTaxable = (salesTaxOptionType: SalesTaxOptionType) => {
    if (!this.ticketItems)
      this.ticketItems = new Map<string | undefined, TicketItem>();

    let hasParts = this.hasParts();

    if (
      (salesTaxOptionType & SalesTaxOptionType.OnTicket) ===
        SalesTaxOptionType.OnTicket &&
      hasParts
    ) {
      this.ticketItems.forEach((ticketItem) => {
        switch (ticketItem.type) {
          case TicketItemType.Labor:
            ticketItem.isTaxable =
              (salesTaxOptionType & SalesTaxOptionType.OnTicket) ===
              SalesTaxOptionType.OnTicket
                ? hasParts
                : (salesTaxOptionType & SalesTaxOptionType.Labor) ===
                  SalesTaxOptionType.Labor;
            this.ticketItems.set(ticketItem.id, ticketItem);
            break;
          default: {
            break;
          }
        }
      });
    }
  };

  setBatchTicketItems = (
    ticketItems: TicketItem[],
    showRemoveAndReplace: boolean
  ) => {
    this.ticketItems.clear();

    ticketItems
      .sort((a, b) => a.order! - b.order!)
      .forEach((item, index) => {
        let newItem = new TicketItem(item);

        if (
          showRemoveAndReplace &&
          newItem.type == TicketItemType.Labor &&
          newItem.description
        )
          newItem.description = newItem.description.replace(
            "R & R",
            "REMOVE & REPLACE"
          );

        this.ticketItems.set(newItem.id, newItem);
      });
  };

  calculateMiscellaneousCharges = (
    miscellaneousCharges: MiscellaneousCharge[]
  ) => {
    let ticketItems = Array.from(this.ticketItems.values()).filter((x) => {
      return x.type === TicketItemType.MiscellaneousCharge;
    });
    let laborTotal: number = 0;
    let partsTotal: number = 0;

    if (ticketItems.length > 0) {
      if (this.ticketItems && this.ticketItems.size > 0) {
        this.ticketItems.forEach((ti) => {
          if (!ti.subTotal)
            ti.subTotal = round2Decimals(
              round2Decimals(ti.rate ? ti.rate : 0.0) *
                round2Decimals(ti.quantity)
            );
          switch (ti.type) {
            case TicketItemType.Labor:
              laborTotal = Number(laborTotal) + Number(ti.subTotal);
              break;
            case TicketItemType.Parts:
              partsTotal = Number(partsTotal) + Number(ti.subTotal);
              break;
          }
        });
      }

      ticketItems.forEach((x) => {
        let misc: MiscellaneousCharge | undefined = miscellaneousCharges.find(
          (y) => y.id === x.miscellaneousChargeId
        );
        if (misc) {
          x.miscellaneousChargeVersion = misc.version;
          x.rate = round2Decimals(
            getMiscChargeAmount(laborTotal, partsTotal, misc)
          );
          x.subTotal = round2Decimals(
            Number(x.rate) * round2Decimals(x.quantity)
          );

          x.taxRate = this.selectedTicketSession?.taxRate ?? 0;
          x.tax = x.isTaxable
            ? round2Decimals(
                Number(x.subTotal ?? 0) * Number(x.taxRate) * Number(0.01)
              )
            : 0.0;
          x.total = Number(x.subTotal ?? 0) + Number(x.tax ?? 0);

          this.ticketItems.set(x.id, x);
        }
      });
    }
  };

  loadTicketSession = async (id: string, workflowStep: TicketWorkflowSteps) => {
    this.loadingInitial = true;
    try {
      const result = await agent.Tickets.getTicketSessions(id, workflowStep);
      runInAction(() => {
        this.selectedTicketSession = new TicketSession(result);
      });
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  resetTicketSession = async (
    id: string,
    workflowStep: TicketWorkflowSteps
  ) => {
    try {
      await agent.Tickets.resetTicketSessions(id, workflowStep);
      runInAction(() => {});
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  loadTicket = async (id: string) => {
    this.loadingInitial = true;
    try {
      const result = await agent.Tickets.getTicket(id);
      runInAction(() => {
        this.selectedTicketSession = new TicketSession(result);
      });
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  setSelectedPONumber = (poNumber: string | undefined) => {
    if (this.poNumber) {
      this.poNumber.value = poNumber;
    }
  };

  setSelectedIsTaxExempt = (isTaxExempt: boolean | undefined) => {
    if (this.selectedTicketSession) {
      this.selectedTicketSession.isTaxExempt = isTaxExempt ?? false;
    }
    this.calculateTotals();
  };

  startTicketSession = (
    id: string,
    settingsTicketWorkflow: SettingsWorkflow,
    settingsTicketDefaults: SettingsTicketDefaults,
    settingsTicketNumber: SettingsTicketNumber
  ) => {
    this.selectedTicketSession =
      this.selectedTicketSession ?? new TicketSession();
    this.selectedTicketSession.id = id;
    this.selectedTicketSession.ticketType =
      settingsTicketWorkflow.defaultTicketType;
    this.selectedTicketSession.returnParts = false;
    this.selectedTicketSession.isTaxExempt = false;
    this.selectedTicketSession.alwaysMiscellaneousChargesAdded = false;
    this.selectedTicketSession.showLaborHours =
      settingsTicketDefaults.showLaborHours;
    this.selectedTicketSession.showSeparateCharges =
      settingsTicketDefaults.showSeparateCharges;
    this.selectedTicketSession.poNumber = settingsTicketNumber.assignAutoPO
      ? settingsTicketNumber.autoPONumber.toString()
      : undefined;
    this.poNumber.value = settingsTicketNumber.assignAutoPO
      ? settingsTicketNumber.autoPONumber.toString()
      : undefined;

    this.selectedTicketSession.rateType = settingsTicketDefaults.rateTypes;
    this.selectedTicketSession.estimatePaymentMethod =
      settingsTicketDefaults.defaultPayTypeId
        ? settingsTicketDefaults.defaultPayTypeId
        : undefined;
    this.selectedTicketSession.estimateCharge =
      settingsTicketDefaults.estimateCharge;

    let promisedDate: Date | undefined = undefined;
    let temp = new Date();
    switch (settingsTicketDefaults.promisedDateOptions) {
      case PromisedDateOptions.Today:
        promisedDate = new Date();
        break;
      case PromisedDateOptions.Tomorrow:
        promisedDate = addDays(temp, 1);
        break;
      case PromisedDateOptions.Blank:
        break;
    }

    this.selectedTicketSession.promisedDate = promisedDate;
    this.selectedTicketSession.estimateDate = new Date();
    this.selectedTicketSession.invoiceDate = new Date();
  };

  createCustomerTicket = async (
    customerId: string,
    id: string,
    vehicleId?: string
  ) => {
    try {
      let createTicket: CreateTicketFormValues = new CreateTicketFormValues();
      createTicket.ticketId = id;
      createTicket.customerId = customerId;
      createTicket.vehicleId = vehicleId;
      await agent.Tickets.createCustomerTicket(createTicket);
      runInAction(() => {
        this.selectedTicketSession = undefined;
        this.ticketSessionRegistry.clear();
      });
    } catch (error) {
      console.log(error);
    }
  };

  setSelectedTicketDescription = (description?: string) => {
    if (this.selectedTicketSession)
      this.selectedTicketSession.description = description;
  };

  setJobLaborsToTicketItems = (
    miscCharges: MiscellaneousCharge[],
    hasParts: boolean,
    salesTaxOptionType: SalesTaxOptionType
  ) => {
    if (!this.ticketItems) {
      this.ticketItems = new Map<string | undefined, TicketItem>();
    }

    Array.from(this.jobLaborRegistry.values()).forEach((jobLabor, index) => {
      if (jobLabor.isComplete) {
        let ticketItem: TicketItem | undefined = this.ticketItems.get(
          jobLabor.id
        );

        if (ticketItem) {
          if (jobLabor.selectedTime != ticketItem.quantity) {
            //potentially update the joblabor?
          }
        } else {
          ticketItem = new TicketItem();

          ticketItem.id = jobLabor.id;
          ticketItem.isTaxable =
            (salesTaxOptionType & SalesTaxOptionType.OnTicket) ===
            SalesTaxOptionType.OnTicket
              ? hasParts
              : (salesTaxOptionType & SalesTaxOptionType.Labor) ===
                SalesTaxOptionType.Labor;

          ticketItem.laborRate = jobLabor.selectedRate;
          ticketItem.jobId = jobLabor.job?.jobId;
          ticketItem.timeType = jobLabor.selectedTimeType;
          ticketItem.order = ticketItem.order ?? this.ticketItems.size;
          ticketItem.description = jobLabor.job ? jobLabor.job.job : "";
          if (index < 1 && this.selectedTicketSession) {
            this.selectedTicketSession.description = ticketItem.description;
          }
          ticketItem.type = TicketItemType.Labor;
          ticketItem.quantity = jobLabor.selectedTimeTotal
            ? round2Decimals(jobLabor.selectedTimeTotal)
            : 0;
          ticketItem.rate = jobLabor.selectedRateValue
            ? round2Decimals(jobLabor.selectedRateValue)
            : undefined;
          ticketItem.unitPrice = jobLabor.selectedRateValue
            ? round2Decimals(jobLabor.selectedRateValue)
            : undefined;
          ticketItem.subTotal = round2Decimals(
            round2Decimals(ticketItem.quantity) *
              round2Decimals(ticketItem.rate ? ticketItem.rate : 0.0)
          );

          ticketItem.taxRate = this.selectedTicketSession?.taxRate ?? 0.0;
          ticketItem.tax = ticketItem.isTaxable
            ? round2Decimals(
                Number(ticketItem.subTotal ?? 0.0) *
                  Number(0.01) *
                  (ticketItem.taxRate ?? 0.0)
              )
            : 0.0;
          ticketItem.total =
            Number(ticketItem.subTotal ?? 0) + Number(ticketItem.tax ?? 0);

          this.ticketItems.set(ticketItem.id ?? "", ticketItem);
        }
      }
    });

    this.setAlwaysMiscChargesToTicketItems(miscCharges);

    this.calculateTotals();
  };

  setAlwaysMiscChargesToTicketItems = (miscCharges: MiscellaneousCharge[]) => {
    let rate = 0.0;
    let currentList = Array.from(this.ticketItems.values());
    let count = this.ticketItems.size;

    if (
      this.selectedTicketSession &&
      this.selectedTicketSession.alwaysMiscellaneousChargesAdded !== true
    ) {
      this.selectedTicketSession.alwaysMiscellaneousChargesAdded = true;

      miscCharges.forEach((misc, index) => {
        let item = currentList.find((x) => {
          return x.miscellaneousChargeId === misc.id;
        });

        if (!item) {
          if (this.selectedTicketSession)
            rate = getMiscChargeAmount(
              this.selectedTicketSession.totalLaborAmount,
              this.selectedTicketSession.totalPartsAmount,
              misc
            );
          let miscItem = new TicketItem();
          miscItem.id = uuid();
          miscItem.type = TicketItemType.MiscellaneousCharge;
          miscItem.description = misc.description;
          miscItem.isTaxable = misc.isTaxable;
          miscItem.miscellaneousChargeId = misc.id;
          miscItem.miscellaneousChargeVersion = misc.version;
          miscItem.order = index + count;
          miscItem.quantity = 1;
          miscItem.rate = round2Decimals(rate);
          miscItem.unitPrice = round2Decimals(rate);
          miscItem.subTotal = round2Decimals(rate);
          miscItem.taxRate = this.selectedTicketSession?.taxRate;
          miscItem.tax = miscItem.isTaxable
            ? round2Decimals(
                Number(miscItem.subTotal) *
                  Number(0.01) *
                  Number(miscItem.taxRate)
              )
            : 0.0;
          miscItem.total =
            Number(miscItem.tax ?? 0.0) + Number(miscItem.subTotal ?? 0.0);

          this.ticketItems.set(miscItem.id, miscItem);
        }
      });
    }
  };

  private getTicketItem = (id: string) => {
    return this.ticketItems.get(id);
  };

  private setTicketItem = (id: string, value: TicketItem) => {
    this.ticketItems.set(id, value);
  };

  updateTicketSession = async (
    workflowSteps: TicketWorkflowSteps,
    id: string,
    vehicle?: VehicleFormValues,
    customer?: CustomerFormValues
  ) => {
    try {
      if (this.selectedTicketSession) {
        let newTicket: TicketSession = new TicketSession(
          this.selectedTicketSession
        );

        let newCustomer: Customer = new Customer(
          customer ?? this.selectedTicketSession.customer
        );
        newTicket.customer = newCustomer;

        let newVehicle: Vehicle = new Vehicle(
          vehicle ?? this.selectedTicketSession.vehicle
        );

        newTicket.vehicle = newVehicle;

        newTicket.poNumber = this.poNumber.value;

        if (this.jobLaborRegistry) {
          newTicket.jobLabors = Array.from(this.jobLaborRegistry.values());
        }

        if (this.laborRegistry) {
          newTicket.labors = Array.from(this.laborRegistry.values());
        }

        newTicket.ticketItems = Array.from(this.ticketItems.values());

        this.calculateTotals();

        newTicket.taxRate = this.selectedTicketSession.taxRate;
        newTicket.taxRate = this.selectedTicketSession.taxRate;
        newTicket.taxRate = this.selectedTicketSession.taxRate;
        newTicket.totalLaborAmount =
          this.selectedTicketSession.totalLaborAmount;
        newTicket.totalPartsAmount =
          this.selectedTicketSession.totalPartsAmount;
        newTicket.totalMiscellaneousAmount =
          this.selectedTicketSession.totalMiscellaneousAmount;
        newTicket.subTotalAmount = this.selectedTicketSession.subTotalAmount;
        newTicket.totalTaxes = this.selectedTicketSession.totalTaxes;
        newTicket.totalAmount = this.selectedTicketSession.totalAmount;
        newTicket.amountDue = round2Decimals(
          this.selectedTicketSession.amountDue
        );

        newTicket.ticketType = this.selectedTicketSession.ticketType;
        newTicket.estimateDate = this.selectedTicketSession.estimateDate;
        newTicket.invoiceDate = this.selectedTicketSession.invoiceDate;
        newTicket.promisedDate = this.selectedTicketSession.promisedDate;
        newTicket.showSeparateCharges =
          this.selectedTicketSession.showSeparateCharges;
        newTicket.isTaxExempt = this.selectedTicketSession.isTaxExempt;
        newTicket.estimatePaymentMethod =
          this.selectedTicketSession.estimatePaymentMethod;
        newTicket.estimateCharge = this.selectedTicketSession.estimateCharge;
        newTicket.showLaborHours = this.selectedTicketSession.showLaborHours;
        newTicket.rateType = this.selectedTicketSession.rateType;
        newTicket.returnParts = this.selectedTicketSession.returnParts;
        newTicket.authorizedContact =
          this.selectedTicketSession.authorizedContact;
        newTicket.otherAuthorizedPersonPhone =
          this.selectedTicketSession.otherAuthorizedPersonPhone;
        newTicket.notes = this.selectedTicketSession.notes;
        newTicket.description = this.selectedTicketSession.description;

        newTicket.workflowStep = workflowSteps;
        newTicket.alwaysMiscellaneousChargesAdded =
          this.selectedTicketSession.alwaysMiscellaneousChargesAdded;

        await agent.Tickets.updateTicketSession(newTicket, id);

        runInAction(() => {
          this.setSelectedTicketSession(newTicket);
          this.ticketSessionRegistry.clear();
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  updateTaxRates = (
    taxRate: number,
    salesTaxOptionType: SalesTaxOptionType
  ) => {
    runInAction(() => {
      if (this.selectedTicketSession) {
        this.selectedTicketSession.taxRate = taxRate;

        this.selectedTicketSession.salesTaxOptionType = salesTaxOptionType;

        this.ticketItems.forEach((ti) => {
          ti.taxRate = taxRate;
          if (!ti.subTotal)
            ti.subTotal = round2Decimals(
              round2Decimals(ti.rate ? ti.rate : 0.0) *
                round2Decimals(ti.quantity)
            );

          switch (ti.type) {
            case TicketItemType.Labor:
              if (
                (this.selectedTicketSession?.salesTaxOptionType ?? 0) &
                SalesTaxOptionType.Labor
              ) {
                ti.isTaxable =
                  ((this.selectedTicketSession?.salesTaxOptionType ?? 0) &
                    SalesTaxOptionType.OnTicket) !==
                    SalesTaxOptionType.OnTicket || this.hasParts();
              } else {
                ti.isTaxable = false;
              }

              break;
            case TicketItemType.Parts:
              ti.isTaxable =
                ((this.selectedTicketSession?.salesTaxOptionType ?? 0) &
                  SalesTaxOptionType.Parts) !==
                SalesTaxOptionType.Parts;
              break;
          }

          ti.tax = ti.isTaxable
            ? round2Decimals(ti.taxRate * 0.01 * ti.subTotal)
            : 0.0;
          ti.total = ti.tax + ti.subTotal;

          this.ticketItems.set(ti.id, ti);
        });

        this.calculateTotals();
      }
    });
  };

  calculateTotals = () => {
    let laborTotal: number = 0.0;
    let partsTotal: number = 0.0;
    let miscellaneousTotal: number = 0.0;

    if (this.ticketItems && this.ticketItems.size > 0) {
      this.ticketItems.forEach((ti) => {
        if (!ti.subTotal)
          ti.subTotal = round2Decimals(
            round2Decimals(ti.rate ? ti.rate : 0.0) *
              round2Decimals(ti.quantity)
          );

        switch (ti.type) {
          case TicketItemType.Labor:
            laborTotal = Number(laborTotal) + Number(ti.subTotal);
            break;
          case TicketItemType.Parts:
            partsTotal = Number(partsTotal) + Number(ti.subTotal);
            break;
          case TicketItemType.MiscellaneousCharge:
          case TicketItemType.AdhocMiscellaneous:
            miscellaneousTotal =
              Number(miscellaneousTotal) + Number(ti.subTotal);
            break;
        }
      });
    }

    if (this.selectedTicketSession) {
      let tax: number = 0.0;

      if (!this.selectedTicketSession.isTaxExempt) {
        if (
          (this.selectedTicketSession.salesTaxOptionType &
            SalesTaxOptionType.Labor) ===
          SalesTaxOptionType.Labor
        ) {
          if (
            (this.selectedTicketSession.salesTaxOptionType &
              SalesTaxOptionType.OnTicket) !==
              SalesTaxOptionType.OnTicket ||
            partsTotal > 0
          ) {
            tax =
              Number(tax) +
              round2Decimals(
                Number(this.selectedTicketSession.taxRate) *
                  0.01 *
                  Number(laborTotal)
              );
          }
        }
        if (
          (this.selectedTicketSession.salesTaxOptionType &
            SalesTaxOptionType.Parts) ===
          SalesTaxOptionType.Parts
        ) {
          tax =
            Number(tax) +
            round2Decimals(
              Number(this.selectedTicketSession.taxRate) *
                0.01 *
                Number(partsTotal)
            );
        }

        let taxableMiscList: TicketItem[] = Array.from(
          this.ticketItems.values()
        ).filter((x) => {
          return (
            x.isTaxable &&
            (x.type === TicketItemType.MiscellaneousCharge ||
              x.type === TicketItemType.AdhocMiscellaneous)
          );
        });

        let taxMisc: number = 0.0;

        taxableMiscList.forEach((ti, index) => {
          if (ti.subTotal) taxMisc = Number(ti.subTotal) + Number(taxMisc);
        });

        tax =
          tax +
          round2Decimals(this.selectedTicketSession.taxRate * 0.01 * taxMisc);
      }

      let subTotal: number =
        round2Decimals(Number(laborTotal)) +
        round2Decimals(Number(partsTotal)) +
        round2Decimals(Number(miscellaneousTotal));
      let total: number = tax + subTotal;

      this.selectedTicketSession.totalLaborAmount = round2Decimals(laborTotal);
      this.selectedTicketSession.totalPartsAmount = round2Decimals(partsTotal);
      this.selectedTicketSession.totalMiscellaneousAmount =
        round2Decimals(miscellaneousTotal);
      this.selectedTicketSession.subTotalAmount = round2Decimals(subTotal);
      this.selectedTicketSession.totalTaxes = round2Decimals(tax);
      this.selectedTicketSession.totalAmount = round2Decimals(total);
    }
  };

  updateSortOrder = (itemId: string, index: number, isUp: boolean) => {
    let exchange = isUp ? index - 1 : index + 1;

    if (this.selectedTicketSession && this.selectedTicketSession.ticketItems) {
      let getMoving = this.ticketItems.get(itemId);
      let swapItem = Array.from(this.ticketItems.values()).find((x) => {
        return x.order === exchange;
      });
      runInAction(() => {
        if (swapItem) {
          swapItem.order = index;
          this.ticketItems.set(swapItem.id, swapItem);
        }
        if (getMoving) {
          getMoving.order = exchange;
          this.ticketItems.set(getMoving.id, getMoving);
        }
      });
    }
  };

  setSelectedTicketSessionAmountDue = (amountDue: number) => {
    if (this.selectedTicketSession)
      this.selectedTicketSession.amountDue = round2Decimals(amountDue);
  };

  setSelectedTicketSessionPaymentReceived = (paymentReceived: number) => {
    if (this.selectedTicketSession)
      this.selectedTicketSession.paymentReceived = paymentReceived;
  };

  updateFinalizeSession = async (
    workflowSteps: TicketWorkflowSteps,
    ticketFinalize: TicketFinalizeFormValues,
    vehicle: VehicleFormValues,
    customer?: CustomerFormValues
  ) => {
    try {
      if (this.selectedTicketSession) {
        let newCustomer: Customer = new Customer(
          customer ?? this.selectedTicketSession.customer
        );
        this.selectedTicketSession.customer = newCustomer;

        let newVehicle: Vehicle = new Vehicle(
          vehicle ?? this.selectedTicketSession.vehicle
        );

        this.selectedTicketSession.vehicle = newVehicle;

        this.selectedTicketSession.poNumber = this.poNumber.value;

        if (this.jobLaborRegistry) {
          this.selectedTicketSession.jobLabors = Array.from(
            this.jobLaborRegistry.values()
          ).filter((x) => {
            return x.isComplete;
          });
        }

        this.selectedTicketSession.workflowStep = workflowSteps;
        this.selectedTicketSession.ticketType = ticketFinalize.ticketType
          ? Number(ticketFinalize.ticketType)
          : 0;
        this.selectedTicketSession.estimateDate = ticketFinalize.estimateDate
          ? new Date(ticketFinalize.estimateDate)
          : new Date();
        this.selectedTicketSession.invoiceDate = ticketFinalize.invoiceDate
          ? new Date(ticketFinalize.invoiceDate)
          : new Date();
        this.selectedTicketSession.promisedDate = ticketFinalize.promisedDate
          ? new Date(ticketFinalize.promisedDate)
          : undefined;

        this.selectedTicketSession.showSeparateCharges =
          ticketFinalize.showSeparateCharges.toString() === "true";
        this.selectedTicketSession.showLaborHours =
          ticketFinalize.showLaborHours.toString() === "true";
        this.selectedTicketSession.rateType = Number(ticketFinalize.rateType);
        this.selectedTicketSession.returnParts =
          ticketFinalize.returnParts.toString() === "true";
        this.selectedTicketSession.authorizedContact =
          ticketFinalize.otherAuthorizedPerson;
        this.selectedTicketSession.otherAuthorizedPersonPhone =
          ticketFinalize.otherAuthorizedPersonPhone;
        this.selectedTicketSession.notes = ticketFinalize.notes;
        this.selectedTicketSession.ticketPayments = Array.from(
          this.ticketPaymentItems.values()
        );

        this.selectedTicketSession.amountDue =
          this.selectedTicketSession.totalAmount;
        this.selectedTicketSession.paymentReceived = 0;
        if (this.selectedTicketSession.ticketType === TicketType.Invoice) {
          const initialValue = 0;
          const payments = round2Decimals(
            this.selectedTicketSession.ticketPayments
              .filter((x) => {
                return (
                  (x.status ?? 0) == PaymentStatus.Succeeded ||
                  (x.status ?? 0) == PaymentStatus.Created
                );
              })
              .reduce(
                (accumulator, value) => accumulator + (value.amount ?? 0.0),
                initialValue
              )
          );
          this.selectedTicketSession.paymentReceived = payments;
          this.selectedTicketSession.amountDue = round2Decimals(
            this.selectedTicketSession.totalAmount - payments
          );
        }

        this.selectedTicketSession.estimateCharge =
          ticketFinalize.estimateCharge;
        this.selectedTicketSession.estimatePaymentMethod =
          ticketFinalize.estimatePaymentMethod;
        await agent.Tickets.finalizeTicketSession(
          this.selectedTicketSession,
          ticketFinalize.id
        );
        runInAction(() => {});
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  // updatePaymentAmounts = async () =>
  // {
  //   if (this.selectedTicketSession) {
  //     let newCustomer: Customer = new Customer(
  //       this.selectedTicketSession.customer
  //     );
  //     this.selectedTicketSession.customer = newCustomer;

  //     let newVehicle: Vehicle = new Vehicle(
  //       this.selectedTicketSession.vehicle
  //     );

  //     this.selectedTicketSession.vehicle = newVehicle;

  //     this.selectedTicketSession.poNumber = this.poNumber.value;

  //     if (this.jobLaborRegistry) {
  //       this.selectedTicketSession.jobLabors = Array.from(
  //         this.jobLaborRegistry.values()
  //       ).filter((x) => {
  //         return x.isComplete;
  //       });
  //     }
  //     this.selectedTicketSession.ticketPayments = Array.from(
  //       this.ticketPaymentItems.values());

  //     this.selectedTicketSession.amountDue =
  //       this.selectedTicketSession.totalAmount;
  //     this.selectedTicketSession.paymentReceived = 0;
  //     if (this.selectedTicketSession.ticketType === TicketType.Invoice) {
  //       const initialValue = 0;
  //       const payments = round2Decimals(
  //         this.selectedTicketSession.ticketPayments
  //           .filter((x) => {
  //             return (
  //               (x.status ?? 0) == PaymentStatus.Succeeded ||
  //               (x.status ?? 0) == PaymentStatus.Created
  //             );
  //           })
  //           .reduce(
  //             (accumulator, value) => accumulator + (value.amount ?? 0.0),
  //             initialValue
  //           )
  //       );
  //       console.log(payments)

  //       this.selectedTicketSession.paymentReceived = payments;
  //       this.selectedTicketSession.amountDue = round2Decimals(
  //         this.selectedTicketSession.totalAmount - payments
  //       );
  //     }

  //     await agent.Tickets.finalizeTicketSession(
  //       this.selectedTicketSession,
  //       this.selectedTicketSession.id
  //     );
  //     runInAction(() => {});
  //   }
  // }

  updateGoBack = async (
    workflowSteps: TicketWorkflowSteps,
    ticketFinalize: TicketFinalizeFormValues,
    vehicle: VehicleFormValues,
    customer?: CustomerFormValues
  ) => {
    try {
      if (this.selectedTicketSession) {
        let newCustomer: Customer = new Customer(
          customer ?? this.selectedTicketSession.customer
        );
        this.selectedTicketSession.customer = newCustomer;

        let newVehicle: Vehicle = new Vehicle(
          vehicle ?? this.selectedTicketSession.vehicle
        );

        this.selectedTicketSession.vehicle = newVehicle;

        this.selectedTicketSession.poNumber = this.poNumber.value;

        if (this.jobLaborRegistry) {
          this.selectedTicketSession.jobLabors = Array.from(
            this.jobLaborRegistry.values()
          ).filter((x) => {
            return x.isComplete;
          });
        }

        this.selectedTicketSession.workflowStep = workflowSteps;
        this.selectedTicketSession.ticketType = ticketFinalize.ticketType
          ? Number(ticketFinalize.ticketType)
          : 0;
        this.selectedTicketSession.estimateDate = ticketFinalize.estimateDate
          ? new Date(ticketFinalize.estimateDate)
          : new Date();
        this.selectedTicketSession.invoiceDate = ticketFinalize.invoiceDate
          ? new Date(ticketFinalize.invoiceDate)
          : new Date();
        this.selectedTicketSession.promisedDate = ticketFinalize.promisedDate
          ? new Date(ticketFinalize.promisedDate)
          : undefined;

        this.selectedTicketSession.showSeparateCharges =
          ticketFinalize.showSeparateCharges.toString() === "true";
        this.selectedTicketSession.showLaborHours =
          ticketFinalize.showLaborHours.toString() === "true";
        this.selectedTicketSession.rateType = Number(ticketFinalize.rateType);
        this.selectedTicketSession.returnParts =
          ticketFinalize.returnParts.toString() === "true";
        this.selectedTicketSession.authorizedContact =
          ticketFinalize.otherAuthorizedPerson;
        this.selectedTicketSession.otherAuthorizedPersonPhone =
          ticketFinalize.otherAuthorizedPersonPhone;
        this.selectedTicketSession.notes = ticketFinalize.notes;
        this.selectedTicketSession.ticketPayments = Array.from(
          this.ticketPaymentItems.values()
        ).filter((x) => {
          return x.amount && x.amount != 0;
        });

        this.selectedTicketSession.amountDue =
          this.selectedTicketSession.totalAmount;
        this.selectedTicketSession.paymentReceived = 0;
        if (this.selectedTicketSession.ticketType === TicketType.Invoice) {
          const initialValue = 0;
          const payments = round2Decimals(
            this.selectedTicketSession.ticketPayments
              .filter((x) => {
                return (
                  (x.status ?? 0) == PaymentStatus.Succeeded ||
                  (x.status ?? 0) == PaymentStatus.Created
                );
              })
              .reduce(
                (accumulator, value) => accumulator + (value.amount ?? 0.0),
                initialValue
              )
          );
          this.selectedTicketSession.paymentReceived = payments;
          this.selectedTicketSession.amountDue = round2Decimals(
            this.selectedTicketSession.totalAmount - payments
          );
        }

        this.selectedTicketSession.estimateCharge =
          ticketFinalize.estimateCharge;
        this.selectedTicketSession.estimatePaymentMethod =
          ticketFinalize.estimatePaymentMethod;
        await agent.Tickets.updateTicketSession(
          this.selectedTicketSession,
          this.selectedTicketSession.id ?? ""
        );
        runInAction(() => {});
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  addTicketShopQuotedTimes = async (id: string) => {
    try {
      await agent.Tickets.addTicketShopQuotedTimes(id);
    } catch (error) {
      console.log(error);
    }
  };

  addTicketMiscellaneousCharges = async (id: string) => {
    try {
      await agent.Tickets.addTicketMiscellaneousCharges(id);
    } catch (error) {
      console.log(error);
    }
  };
  addQuickVinLogs = async (id: string) => {
    try {
      await agent.Tickets.addQuickVinLogs(id);
    } catch (error) {
      console.log(error);
    }
  };

  removeTicketItem = (
    id: string,
    miscellaneousCharges: MiscellaneousCharge[],
    selectedVehicle?: VehicleFormValues,
    selectedCustomer?: CustomerFormValues
  ) => {
    if (this.ticketItems && this.ticketItems.size > 0) {
      let ticketItem = this.ticketItems.get(id);
      if (
        ticketItem?.type === TicketItemType.Labor &&
        this.selectedTicketSession
      ) {
        let jobLabor = this.selectedTicketSession.jobLabors.find((x) => {
          return x.id === id;
        });
        //remove from the other lists too
        this.selectedTicketSession.jobLabors =
          this.selectedTicketSession.jobLabors.filter((x) => {
            return (x.id ?? "") !== id;
          });

        this.selectedTicketSession.labors =
          this.selectedTicketSession.labors.filter((x) => {
            return (x.id ?? "") !== jobLabor?.jobLaborId;
          });

        this.jobLaborRegistry.delete(jobLabor?.id);
        this.laborRegistry.delete(jobLabor?.jobLaborId?.toString());
      }

      this.ticketItems.delete(id);
      //update the order
      Array.from(this.ticketItems.values())
        .sort((a, b) => a.order! - b.order!)
        .map((item, index) => {
          item.order = index;
          this.ticketItems.set(item.id, item);
        });

      this.calculateMiscellaneousCharges(miscellaneousCharges);
      this.calculateTotals();
      this.updateTicketSession(
        TicketWorkflowSteps.Summary,
        this.selectedTicketSession?.id ?? "",
        selectedVehicle,
        selectedCustomer
      );
    }
  };

  resetTicketItems = async () => {
    this.ticketItems = new Map<string | undefined, TicketItem>();
  };

  get sortedSelectedTicketTicketItems() {
    if (
      this.selectedTicketSession &&
      this.ticketItems &&
      this.ticketItems.size > 0
    )
      return this.selectedTicketSession.ticketItems.sort(
        (a, b) => a.order! - b.order!
      );
    else {
      return [];
    }
  }

  setSelectedJobLabor = (jobLabor: JobLabor) => {
    this.selectedJobLabor = jobLabor;
  };

  newSelectedJobLabor = () => {
    if (this.selectedJobLabor) {
      let newJobLabor: JobLabor = new JobLabor();
      newJobLabor.id = uuid();
      newJobLabor.job = new Job();
      if (this.selectedJobLabor.job) {
        newJobLabor.job.jobCategoryId = this.selectedJobLabor.job.jobCategoryId;
        newJobLabor.job.jobType = this.selectedJobLabor.job.jobType;
        newJobLabor.job.jobTypeId = this.selectedJobLabor.job.jobTypeId;
      }
      this.setSelectedJobLabor(newJobLabor);
    }
  };

  setSelectedJobLaborJob = (job: Job) => {
    if (this.selectedJobLabor) this.selectedJobLabor.job = job;
  };

  setSelectedJobLaborJobCategory = (jobCategory: JobCategory) => {
    if (this.selectedJobLabor?.job)
      this.selectedJobLabor.job.jobCategoryId = jobCategory;
  };

  setSelectedJobLaborJobType = (jobTypeId: number, jobType: string) => {
    if (this.selectedJobLabor?.job) {
      this.selectedJobLabor.job.jobType = jobType;
      this.selectedJobLabor.job.jobTypeId = jobTypeId;
    }
  };

  setSelectedJobLaborJobName = (jobId: number, job: string) => {
    if (this.selectedJobLabor?.job) {
      this.selectedJobLabor.job.job = job;
      this.selectedJobLabor.job.jobId = jobId;
    }
  };

  setSelectedJobLaborJobRequiresEngine = (requiresEngine: boolean) => {
    if (this.selectedJobLabor?.job) {
      this.selectedJobLabor.job.jobRequiresEngine = requiresEngine;
    }
  };

  setSelectedJobLaborJobAddon = (jobAddons: JobAddOn[]) => {
    if (this.selectedJobLabor) {
      if (!this.selectedJobLabor.selectedJobAddons)
        this.selectedJobLabor.selectedJobAddons = [];

      this.selectedJobLabor.selectedJobAddons = jobAddons;
    }
  };

  resetSelectedJobLaborJobAddons = () => {
    if (this.selectedJobLabor) this.selectedJobLabor.selectedJobAddons = [];
  };

  resetJobLabors = () => {
    this.jobLaborRegistry.clear();
  };
  resetLabors = () => {
    if (this.laborRegistry) {
      this.laborRegistry.clear();
    }
  };

  addJobLabor = (jobLabor: JobLabor) => {
    if (!this.jobLaborRegistry)
      this.jobLaborRegistry = new Map<string | undefined, JobLabor>();
    if (jobLabor.id) jobLabor.id = uuid();

    this.jobLaborRegistry.set(jobLabor.id, jobLabor);
  };

  updatesJobLabor = (jobLabor: JobLabor) => {
    if (!this.jobLaborRegistry) {
      this.jobLaborRegistry = new Map<string | undefined, JobLabor>();
      if (jobLabor.id) jobLabor.id = uuid();
      this.jobLaborRegistry.set(jobLabor.id, jobLabor);
    } else if (jobLabor.id) {
      let updatedJob = {
        ...this.getJobLaborItem(jobLabor.id),
        ...jobLabor,
      };
      this.jobLaborRegistry.set(jobLabor.id, updatedJob);
    }
  };

  private getJobLaborItem = (id: string) => {
    return this.jobLaborRegistry.get(id);
  };

  handleSetSelectedRate = (
    labor: Labor,
    timeType: TimeType,
    selectedRate: string,
    selectedRateValue: number,
    time: number,
    selectedTimeTotal: number,
    isCustom: boolean,
    availableTimes: TimeType,
    isComplete: boolean,
    showRemoveAndReplace: boolean
  ) => {
    if (this.selectedJobLabor) {
      this.selectedJobLabor.jobLaborId = labor.id;
      this.selectedJobLabor.averageTime = labor.averageTime;
      this.selectedJobLabor.highTime = labor.highTime;
      this.selectedJobLabor.lowTime = labor.lowTime;
      this.selectedJobLabor.laborHash = labor.laborHash;

      this.selectedJobLabor.selectedTimeType = timeType;
      this.selectedJobLabor.selectedRate = selectedRate;
      this.selectedJobLabor.selectedRateValue =
        round2Decimals(selectedRateValue);
      this.selectedJobLabor.selectedTime = time;
      this.selectedJobLabor.selectedTimeTotal = selectedTimeTotal;
      this.selectedJobLabor.isCustom = isCustom;
      this.selectedJobLabor.availableTimes = availableTimes;
      this.selectedJobLabor.isComplete = isComplete;

      if (showRemoveAndReplace && this.selectedJobLabor.job?.job)
        this.selectedJobLabor.job.job = this.selectedJobLabor.job.job.replace(
          "R & R",
          "REMOVE & REPLACE"
        );
    }
  };

  getJobAddonsList = (jobLabors: JobLabor[], labor: Labor) => {
    let jobAddons: JobAddOn[] = [];
    if (jobLabors && this.selectedJobLabor && labor.jobAddons) {
      let findings: JobAddOn | undefined;
      let found: boolean = false;

      labor.jobAddons.forEach((addon) => {
        findings = undefined;
        found = false;
        jobLabors.forEach((x) => {
          if (x.selectedJobAddons) {
            findings = x.selectedJobAddons.find((y) => {
              return y.jobAddonId === addon.jobAddonId;
            });
            if (findings && this.selectedJobLabor) {
              if (!this.selectedJobLabor.selectedJobAddons)
                this.selectedJobLabor.selectedJobAddons = [];

              let alreadyHere = this.selectedJobLabor.selectedJobAddons.find(
                (z) => {
                  return z.jobAddonId === addon.jobAddonId;
                }
              );
              if (!alreadyHere) {
                this.selectedJobLabor.selectedJobAddons.push(findings);
              }
              found = true;
            }
          }
        });

        if (!found && this.laborRegistry.size > 0) {
          this.laborRegistry.forEach((labor, index) => {
            if (labor.hasJobAddon)
              findings = labor.jobAddons.find((x) => {
                return x.jobAddonId === addon.jobAddonId;
              });

            if (findings) {
              //don't show again
              found = true;
            }
          });
        }

        if (!found) {
          jobAddons.push(addon);
        }
      });
    }
    return jobAddons;
  };

  calculateLaborAmount = () => {
    let amount = 0.0;
    if (this.jobLaborRegistry && this.jobLaborRegistry.size > 0) {
      this.jobLaborRegistry.forEach((labor) => {
        if (labor.selectedTimeTotal && labor.selectedRateValue)
          amount =
            round2Decimals(
              round2Decimals(labor.selectedTimeTotal) *
                round2Decimals(labor.selectedRateValue)
            ) + amount;
      });
    }

    return amount;
  };

  get jobLaborsCompleted() {
    let laborList: JobLabor[] = [];

    if (this.jobLaborRegistry && this.jobLaborRegistry.size > 0) {
      laborList = Array.from(this.jobLaborRegistry.values()).filter((x) => {
        return x.isComplete === true;
      });
    }

    return laborList;
  }

  removeLaborAndJobLabor = (id: string) => {
    if (this.jobLaborRegistry.size > 0 && this.laborRegistry.size > 0) {
      let jobLabor = this.jobLaborRegistry.get(id);
      if (jobLabor) {
        this.jobLaborRegistry.delete(id);

        let findLabor = Array.from(this.jobLaborRegistry.values()).filter(
          (x) => {
            return x.jobLaborId === jobLabor?.jobLaborId;
          }
        );

        if (findLabor.length < 1) {
          this.laborRegistry.delete(jobLabor.jobLaborId?.toString());
        }
      }
    }
  };

  populateJobLabors = (loadJobLabors: JobLabor[]) => {
    if (this.jobLaborRegistry.size < 1) {
      loadJobLabors.forEach((jobs) => this.jobLaborRegistry.set(jobs.id, jobs));
    } else {
      //something might be here
      loadJobLabors.forEach((jobs, index) => {
        if (jobs.id) {
          let updatedJob = {
            ...this.getJobLaborItem(jobs.id),
            ...jobs,
          };
          this.jobLaborRegistry.set(jobs.id, updatedJob);
        }
      });
    }
  };

  addLabor = (labor: Labor) => {
    if (!this.laborRegistry)
      this.laborRegistry = new Map<string | undefined, Labor>();

    this.laborRegistry.set(labor.id?.toString() ?? labor.shopTimeId, labor);
  };

  populateLaborMap = (loadLabor: Labor[]) => {
    if (this.laborRegistry.size < 1) {
      loadLabor.forEach((labor) =>
        this.laborRegistry.set(labor.id?.toString() ?? labor.shopTimeId, labor)
      );
    } else {
      //something might be here
      loadLabor.forEach((labor) => {
        if (labor.id) {
          let updatedLabor = {
            ...this.getLaborItem(labor.id?.toString() ?? labor.shopTimeId),
            ...labor,
          };
          this.laborRegistry.set(
            labor.id?.toString() ?? labor.shopTimeId,
            updatedLabor
          );
        }
      });
    }
  };

  private getLaborItem = (id: string) => {
    return this.laborRegistry.get(id);
  };

  setTechTrackingOnTicketItem = (ticketItemId: string, value: string) => {
    let item = this.getTicketItem(ticketItemId);
    if (item) {
      item.technicianId = value;
      this.setTicketItem(ticketItemId, item);
    }
  };

  setBatchTechTrackingOnTicketItem = (
    value: string | undefined,
    addLabor: boolean,
    addParts: boolean
  ) => {
    this.ticketItems.forEach((x) => {
      if (
        (addLabor && x.type === TicketItemType.Labor) ||
        (addParts && x.type === TicketItemType.Parts)
      ) {
        x.technicianId = value;
        this.ticketItems.set(x.id ?? "", x);
      }
    });

    console.log(this.ticketItems);
  };

  updateTechTracking = async () => {
    try {
      if (this.selectedTicketSession) {
        this.selectedTicketSession.ticketItems = Array.from(
          this.ticketItems.values()
        );

        await agent.Tickets.assignTechnicianToTicketItem(
          this.selectedTicketSession.id ?? "",
          this.selectedTicketSession
        );
        runInAction(() => {});
      }
    } catch (error) {
      console.log(error);
    }
  };

  get successfulTicketPayments() {
    return Array.from(this.ticketPaymentItems.values())
      .filter((x) => {
        return (
          (x.status ?? 0) == PaymentStatus.Succeeded ||
          (x.status ?? 0) == PaymentStatus.Declined
        );
      })
      .sort((a, b) => a.createdDate!.getTime() - b.createdDate!.getTime());
  }

  get createdAndSuccessfulTicketPayments() {
    return Array.from(this.ticketPaymentItems.values())
      .filter((x) => {
        return (
          (x.status ?? 0) == PaymentStatus.Succeeded ||
          (x.status ?? 0) == PaymentStatus.Created
        );
      })
      .sort((a, b) => a.createdDate!.getTime() - b.createdDate!.getTime());
  }

  processRefund = async (
    ticketPaymentItem: TicketPaymentItem,
    refundAmount: number,
    isPaymentProcess: boolean
  ) => {
    try {
      if (this.selectedTicketSession)
        await agent.Tickets.reverseTicketPayments(
          this.selectedTicketSession.id ?? "",
          ticketPaymentItem.id ?? "",
          isPaymentProcess,
          refundAmount
        );
      return;
      //}
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  // calculateTotalPayments(): number {
  //   const initialValue = 0;
  //   return round2Decimals(
  //     this.selectedTicketSession?.ticketPayments.reduce(
  //       (accumulator, value) => accumulator + (value.amount ?? 0.0),
  //       initialValue
  //     ) ?? 0
  //   );
  // }

  get laborTicketItems() {
    return Array.from(this.ticketItems.values())
      .filter((x) => {
        return x.type === TicketItemType.Labor;
      })
      .sort((a, b) => a.order! - b.order!);
  }

  get partsTicketItems() {
    return Array.from(this.ticketItems.values())
      .filter((x) => {
        return x.type === TicketItemType.Parts;
      })
      .sort((a, b) => a.order! - b.order!);
  }

  get processPayments(): boolean {
    let process = false;
    if (this.ticketPaymentItems && this.ticketPaymentItems.size > 0) {
      let cardPayments = Array.from(this.ticketPaymentItems.values()).filter(
        (item) => {
          return item.status === PaymentStatus.Created;
        }
      );
      process = cardPayments && cardPayments.length > 0;
    }

    return process;
  }

  hasLabor = (): boolean => {
    let returnValue = false;

    let laborItem = this.selectedTicketSession?.ticketItems.find((x) => {
      return x.type == TicketItemType.Labor;
    });

    if (laborItem) returnValue = true;

    return returnValue;
  };

  hasParts = (): boolean => {
    let returnValue = false;

    if (
      this.selectedTicketSession?.ticketItems &&
      this.selectedTicketSession.ticketItems.length > 0
    ) {
      let partItem = this.selectedTicketSession?.ticketItems.find((x) => {
        return x.type == TicketItemType.Parts;
      });

      if (partItem) returnValue = true;
    }
    return returnValue;
  };

  validatePaymentTotals = async () => {
    try {
      if (this.selectedTicketSession)
        await agent.Tickets.validatePaymentTotals(
          this.selectedTicketSession.id ?? ""
        );
      return;
      //}
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}
