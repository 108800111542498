import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../../api/agent";
import { Pagination, PagingParams } from "../../../models/pagination";
import { RecurringChargesReport } from "../../../models/systemAdmin";
import { sortingStrings } from "../../../common/util/functions";

export default class RecurringChargesStore {
  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.predicate.keys(),
      () => {
        this.pagingParams = new PagingParams();
        this.RecurringChargeReportRegistry.clear();
      }
    );
  }

  loadingInitial = false;
  pagination: Pagination | null = null;
  pagingParams = new PagingParams(1, 25);
  predicate = new Map().set("all", true);
  RecurringChargeReportRegistry = new Map<string, RecurringChargesReport>();
  grandTotal: number | null = null;

  loadRecurringChargeTotal = async() => {
    try {
      const filterDate = this.predicate.get('FilterDate');
      const result = await agent.SystemAdminReports.getRecurringChargesTotal(
        filterDate ? new URLSearchParams({'FilterDate': filterDate.toISOString()}) : undefined
      );
      runInAction(() => {
        this.setGrandTotal(result.total);
      })
    } catch (error) {
      console.log(error);
    }
  }

  private setGrandTotal = (grandTotal: number) => {
    this.grandTotal = grandTotal;
  }

  get getRecurringChargeTotal() {
    return this.grandTotal;
  }

  loadRecurringChargeReport = async () => {
    this.loadingInitial = true;
    try {
      this.RecurringChargeReportRegistry.clear();
      const result = await agent.SystemAdminReports.getRecurringChargesReport(
        this.axiosParams
      );
      runInAction(() => {
        result.data.forEach((x) => {
          this.setRecurringChargeReport(x);
        });
        this.setPagination(result.pagination);
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  private setRecurringChargeReport = (
    recurringChargeReport: RecurringChargesReport
  ) => {
    this.RecurringChargeReportRegistry.set(
      recurringChargeReport.id,
      recurringChargeReport
    );
  };

  get getRecurringChargesReport() {
    return Array.from(this.RecurringChargeReportRegistry.values()).sort(
      (a, b) => {
        return (
          sortingStrings(
            a.clientName!.toLowerCase(),
            b.clientName!.toLowerCase()
          ) ?? 0
        );
      }
    );
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "FilterDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = new PagingParams(pagingParams.pageNumber, 25);
  };

  setPredicate = (filterDate: Date) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        this.predicate.delete(key);
      });
    };
    resetPredicate();
    if (filterDate) this.predicate.set("FilterDate", filterDate);
  };
}
