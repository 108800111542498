import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../../api/agent";
import { SubscriberReport } from "../../../models/systemAdmin";

export default class SubscriberReportStore {
  constructor() {
    makeAutoObservable(this);
    reaction(
      () => {},
      () => {
        this.subscriberReportRegistry.clear();
        this.loadSubscriberReport();
      }
    );
  }

  loadingInitial = false;
  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };
  subscriberReportRegistry = new Map<number, SubscriberReport>();

  loadSubscriberReport = async () => {
    this.loadingInitial = true;
    try {
      this.subscriberReportRegistry.clear();
      const result = await agent.SystemAdminReports.getSubscriberReport();
      runInAction(() => {
        result.forEach((subscriberReport) => {
          this.setSubscriberReport(subscriberReport);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  private setSubscriberReport = (subscriberReport: SubscriberReport) => {
     this.subscriberReportRegistry.set(
         subscriberReport.application,
         subscriberReport
     );
  };

  get getSubscriberReport() {
    return Array.from(this.subscriberReportRegistry.values());
  }
}
