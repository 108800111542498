import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Table } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import PageHeader from "../../../app/layout/PageHeader";


export default observer(function NextChargeAdjustments() {

  const { t } = useTranslation(["common", "systemAdmin"]);
  const { nextChargeAdjustmentsStore } = useStore();
  const { loadNextChargeAdjustments, getNextChargeAdjustments } =
    nextChargeAdjustmentsStore;

  const [filterDate, setFilterDate] = useState<Date | Date[] | undefined>(
    undefined
  );

  useEffect(() => {
    loadNextChargeAdjustments();
  }, [loadNextChargeAdjustments]);

  return (
    <>
      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column>
            <PageHeader
              type="h1"
              header={"Next Charge Adjustments"}
              className="modal-text-color"
              textAlign="left"
              divider={false}
              addTitle
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid stackable>
        <Grid.Row columns={1}>
          <Table basic="very" striped unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="left">
                  Days Between Last Charge and Next Charge
                </Table.HeaderCell>
                <Table.HeaderCell>Count</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell textAlign="left">29</Table.Cell>
                    <Table.Cell>{"x"}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="left">30</Table.Cell>
                    <Table.Cell>{"x"}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="left">90</Table.Cell>
                    <Table.Cell>{"x"}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="left">90</Table.Cell>
                    <Table.Cell>{"x"}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="left">91</Table.Cell>
                    <Table.Cell>{"x"}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="left">364</Table.Cell>
                    <Table.Cell>{"x"}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="left">365</Table.Cell>
                    <Table.Cell>{"x"}</Table.Cell>
                  </Table.Row>
                </Table.Body>
          </Table>
        </Grid.Row>
      </Grid>
    </>
  );
});
