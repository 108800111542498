import { observer } from "mobx-react-lite";
import { Button, Divider, Grid, Header, Loader } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import ServiceOverviewComponent from "./ServiceOverviewComponent";
import ActiveCompanyStatusesComponent from "./ActiveCompanyStatusesComponent";
import { useStore } from "../../../app/stores/store";
import { toast } from "react-toastify";
import { useState } from "react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import CompareServiceOverviewComponent from "./CompareServiceOverviewComponent";
import CompareActiveCompanyStatusesComponent from "./CompareActiveCompanyStatusesComponent";
import PageHeader from "../../../app/layout/PageHeader";

export default observer(function Overview() {
  const { t } = useTranslation(["common", "companySettings"]);
  const { statsStore } = useStore();
  const {
    forceStatsRefresh,
    clearStatsOverview,
    loading,
    setLoading,
    setPredicate,
    loadStatsCompare,
    clearStatsCompare,
  } = statsStore;
  const [compare, setcompare] = useState(false);

  return (
    <>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
            <PageHeader
              type="h1"
              header={t("stats.service_overview", { ns: "systemAdmin" })}
              className="modal-text-color"
              textAlign="left"
              divider={false}
              addTitle
            />
          </Grid.Column>
          <Grid.Column>
            <Button
              loading={loading}
              className="save_button ui button"
              icon="refresh"
              type="button"
              content="Force Data Refresh"
              floated="right"
              size="small"
              disabled={loading}
              onClick={() => {
                setLoading(true);
                forceStatsRefresh().then(() => {
                  clearStatsOverview();
                  toast.success("Stats has refreshed successfully!");
                  setLoading(false);
                });
              }}
            />
            <Button
              loading={loading}
              className="save_button ui button"
              icon={compare ? "chevron up" : "chevron down"}
              type="button"
              content={compare ? "Exit Compare" : "Compare"}
              floated="right"
              size="small"
              disabled={loading}
              onClick={() => {
                setcompare(!compare);
                clearStatsCompare();
              }}
            />
          </Grid.Column>
        </Grid.Row>
        {compare && (
          <>
            <Grid.Row columns={2}>
              <Grid.Column>Start Date:</Grid.Column>
              <Grid.Column>End Date:</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <SemanticDatepicker
                  onChange={(e, d) => setPredicate("startDate", d.value!)}
                ></SemanticDatepicker>
              </Grid.Column>
              <Grid.Column>
                <SemanticDatepicker
                  onChange={(e, d) => setPredicate("endDate", d.value!)}
                ></SemanticDatepicker>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
              <Button
                loading={loading}
                className="save_button ui button"
                icon="refresh"
                type="button"
                content="Run Compare"
                floated="left"
                size="small"
                disabled={loading}
                onClick={() => loadStatsCompare()}
              />
              </Grid.Column>
            </Grid.Row>
          </>
        )}
        <Divider />
        <Grid.Row>
          {loading ? (
            <Loader active />
          ) : compare ? (
            <CompareServiceOverviewComponent />
          ) : (
            <ServiceOverviewComponent />
          )}
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Header
              as="h2"
              content={t("stats.active_status", { ns: "systemAdmin" })}
              className="modal-text-color"
              textAlign="left"
            />
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row>
          {loading ? (
            <Loader active />
          ) : compare ? (
            <CompareActiveCompanyStatusesComponent />
          ) : (
            <ActiveCompanyStatusesComponent />
          )}
        </Grid.Row>
      </Grid>
    </>
  );
});
