import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Card,
  Icon,
  Divider,
  Form,
  Message,
  Radio,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import PageHeader from "../../../app/layout/PageHeader";
import { observer } from "mobx-react-lite";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../app/stores/store";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import StateSelect from "../../../app/common/form/StateSelect";
import { ErrorMessage, FieldArray, Formik } from "formik";
import { QuickVinLookup } from "../../../app/models/quickVin";
import MyCapitalsInput from "../../../app/common/form/MyCapitalsInput";
import { ClientStatus, TicketWorkflowSteps } from "../../../app/models/enums";
import { Vehicle } from "../../../app/models/vehicle";
import ValidationErrors from "../../errors/ValidationErrors";

interface Props {
  isActive: boolean;
}

export default observer(function QuickVinLookupForm({ isActive }: Props) {
  const { t } = useTranslation(["common", "translation", "recentTickets"]);

  const navigate = useNavigate();

  const {
    customerStore,
    vehicleStore,
    ticketSessionStore,
    modalStore,
    quickVinStore,
    userStore,
  } = useStore();

  const { selectedCustomer } = customerStore;
  const { updateTicketSession } = ticketSessionStore;

  const {
    quickVinLookup,
    lookUpVehicle,
    lookupRegistry,
    getVehicles,
    resetLookupRegistry,
    getVehicle,
    isVehicleComplete,
    resetQuickVin,
  } = quickVinStore;

  const { user } = userStore;

  const { setSelectedVehicle } = vehicleStore;

  const { id } = useParams<{ id: string }>();
  const { complete } = useParams<{ complete: string }>();

  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  let status: boolean =
    (user?.status ?? 0 & ClientStatus.Trial) !== ClientStatus.Trial;
  const [myIsSubmitting, setMyIsSubmitting] = useState<boolean>(false);
  const [isLookup] = useState<boolean>(!isActive && status);

  const validationSchema = Yup.object({
    VIN: Yup.string().test(
      "vin-conditional",
      "VIN is required when both State and Tag are empty, and must be empty if either State or Tag is filled",
      function (value) {
        const { state, tag } = this.parent;
        const isVinFilled = (value?.trim().length ?? 0) > 0;
        const isStateFilled = (state?.trim().length ?? 0) > 0;
        const isTagFilled = (tag?.trim().length ?? 0) > 0;

        if (!isVinFilled && !isStateFilled && !isTagFilled) {
          return this.createError({
            message: t("quickvin.required_vin", { ns: "errors" }),
          });
        }
        if (isVinFilled && (isStateFilled || isTagFilled)) {
          return this.createError({
            message: t("quickvin.required_vin_when_tag_or_state_is_not_empty", {
              ns: "errors",
            }),
          });
        }
        return true;
      }
    ),

    state: Yup.string().test(
      "state-conditional",
      "State is required when Tag is filled and must be empty if VIN is filled",
      function (value) {
        const { vin, tag } = this.parent;
        const isVinFilled = (vin?.trim().length ?? 0) > 0;
        const isStateFilled = (value?.trim().length ?? 0) > 0;
        const isTagFilled = (tag?.trim().length ?? 0) > 0;

        if (isTagFilled && !isStateFilled) {
          return this.createError({
            message: t("quickvin.required_state_when_tag_is_not_empty", {
              ns: "errors",
            }),
          });
        }
        if (isVinFilled && isStateFilled) {
          return this.createError({
            message: t(
              "quickvin.required_state_to_be_empty_when_vin_is_not_empty",
              { ns: "errors" }
            ),
          });
        }
        return true;
      }
    ),

    tag: Yup.string().test(
      "tag-conditional",
      "Tag is required when State is filled and must be empty if VIN is filled",
      function (value) {
        const { vin, state } = this.parent;
        const isVinFilled = (vin?.trim().length ?? 0) > 0;
        const isTagFilled = (value?.trim().length ?? 0) > 0;
        const isStateFilled = (state?.trim().length ?? 0) > 0;

        if (isStateFilled && !isTagFilled) {
          return this.createError({
            message: t("quickvin.required_tag_when_state_is_not_empty", {
              ns: "errors",
            }),
          });
        }
        if (isVinFilled && isTagFilled) {
          return this.createError({
            message: t(
              "quickvin.required_tag_to_be_empty_when_vin_is_not_empty",
              { ns: "errors" }
            ),
          });
        }
        return true;
      }
    ),
  });

  useEffect(() => {
    resetQuickVin();
  }, [resetQuickVin]);

  function handleFormSubmit(value: QuickVinLookup) {
    let index: number | undefined = value.selectedVehicle;

    if (!index) index = 0;

    let vehicle = getVehicle(index);

    if (vehicle) {
      setSelectedVehicle(new Vehicle(vehicle));

      if (isVehicleComplete(vehicle)) {
        updateTicketSession(
          TicketWorkflowSteps.Vehicle,
          id ?? "",
          vehicle,
          selectedCustomer
        ).then(() => {
          navigate(`/ticket/jobCategory/${id}/${complete}`);
          modalStore.closeModal();
        });
      } else {
        modalStore.closeModal();
      }
    }
  }

  return (
    <>
      <PageHeader
        type="h3"
        header={t("lookupVehicle", { ns: "tickets" })}
        className="modal-text-color"
        textAlign="left"
        divider={true}
        addTitle={false}
      />
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={quickVinLookup}
        onSubmit={(values) => handleFormSubmit(values)}
      >
        {({
          handleSubmit,
          isValid,
          isSubmitting,
          dirty,
          handleBlur,
          handleChange,
          errors,
          values,
          touched,
          setFieldValue,
          setErrors,
        }) => (
          <Form
            className="ui form error"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Grid stackable>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Card>
                    <Card.Header className="card-header" as="h3">
                      {t("vehicleTag", { ns: "tickets" })}
                    </Card.Header>
                    <Card.Content>
                      <MyCapitalsInput
                        placeholder={""}
                        name={"tag"}
                        label={`${t("tag", { ns: "common" })}:`}
                        maxLength={50}
                      />
                      <StateSelect
                        placeholder={""}
                        name={"state"}
                        label={`${t("address.state", { ns: "common" })}:`}
                      />
                    </Card.Content>
                  </Card>
                </Grid.Column>
                <Grid.Column>
                  <Card className="fill-height">
                    <Card.Header className="card-header" as="h3">
                      {t("vinLookup", { ns: "tickets" })}
                    </Card.Header>
                    <Card.Content>
                      <MyCapitalsInput
                        placeholder={""}
                        name={"VIN"}
                        label={`${t("estimate.vin", { ns: "tickets" })}:`}
                        maxLength={20}
                      />
                    </Card.Content>
                  </Card>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row centered>
                <Grid.Column width={7}>
                  <Button
                    type="button"
                    disabled={myIsSubmitting || isLookup}
                    loading={myIsSubmitting}
                    onClick={() => {
                      resetLookupRegistry();
                      setIsSuccessful(false);
                      setMyIsSubmitting(true);
                      lookUpVehicle(values)
                        .then(() => {
                          setIsSuccessful(true);
                          setMyIsSubmitting(false);
                        })
                        .catch((error) => {
                          setErrors({ error: error });
                          setMyIsSubmitting(false);
                        });
                    }}
                  >
                    <Icon name="search" />
                    {t("look_up", { ns: "tickets" })}
                  </Button>
                </Grid.Column>
              </Grid.Row>
              {isSuccessful &&
                (getVehicles && getVehicles.length > 0 ? (
                  <Grid.Row>
                    <Grid.Column>
                      <Message color="green">
                        <FieldArray
                          name={"selectedVehicle"}
                          render={() => (
                            <>
                              {getVehicles.map((Vehicle, index) =>
                                getVehicles.length === 1 ? (
                                  <>
                                    {`${Vehicle.year} ${
                                      Vehicle.makeName ?? ""
                                    } ${Vehicle.modelName ?? ""} ${
                                      Vehicle.engineName ?? ""
                                    }`}
                                  </>
                                ) : (
                                  <Form.Field
                                    key={`selectedVehicle[${Vehicle.id}]`}
                                  >
                                    <Radio
                                      id={`selectedVehicle[${index}]`}
                                      key={`selectedVehicle[${Vehicle.id}]`}
                                      value={index}
                                      name="selectedVehicle"
                                      onChange={(e, d) => {
                                        setFieldValue(
                                          "selectedVehicle",
                                          Number(d.value)
                                        );
                                        values.vin = Vehicle.vin;
                                      }}
                                      label={`${Vehicle.year} ${Vehicle.makeName} ${Vehicle.modelName} ${Vehicle.engineName}`}
                                      checked={
                                        values.selectedVehicle === Number(index)
                                      }
                                    />
                                  </Form.Field>
                                )
                              )}
                            </>
                          )}
                        />
                      </Message>
                    </Grid.Column>
                  </Grid.Row>
                ) : (
                  <Grid.Row>
                    <Grid.Column>
                      <Message color="yellow" content="No matches..." />
                    </Grid.Column>
                  </Grid.Row>
                ))}
              {isLookup && (
                <Grid.Row>
                  <Grid.Column>
                    <Message color="red">
                      {t("quickvin.enableQuickVin", { ns: "errors" })}{" "}
                      <Link
                        to="/settings/quickvin"
                        onClick={() => {
                          modalStore.closeModal();
                        }}
                      >
                        {t("quickvin", { ns: "common" })}
                      </Link>
                    </Message>
                  </Grid.Column>
                </Grid.Row>
              )}
              {errors.error && (
                <Grid.Row>
                  <Grid.Column>
                    <ValidationErrors errors={errors.error} />
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
            <Divider />
            <Button
              className="save_button"
              color="green"
              type="submit"
              disabled={lookupRegistry.size < 1}
              loading={isSubmitting}
            >
              <Icon name="check" />
              {t("selectMatchedVehicle", { ns: "tickets" })}
            </Button>
            <Button
              className="save_button"
              onClick={() => modalStore.closeModal()}
              type="reset"
            >
              <Icon name="x" />
              {t("cancel", { ns: "common" })}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
});
