import axios from "axios";
import { PaginatedResult } from "../../models/pagination";
import {
  StatsOverview,
  SubscriptionGap,
  ExtensionReport,
  MultipleRecurring,
  RecurringChargesReport,
  CancellationReport,
  NextChargeAdjustments,
  EarlyChargeReport,
  TrialActivityReport,
  SubscriberReport,
  ClientUserStats,
  ClientUserTotalStat,
  ClientNoLoginReport,
  ActiveServiceType,
  AuthorizeNetReport,
  RecurringChargesTotal,
  SubscriptionGapOverview
} from "../../models/systemAdmin";
import { requests, responseBody } from "../Requests";
import { format } from "date-fns";

export const SystemAdminReports = {
  getAuthorizeNetReport: (date: Date) =>
    requests.get<AuthorizeNetReport[]>(`/SystemAdminReports/ListAuthorizeNetReportDetails?startDate=${format(date, 'P')}&endDate=${format(date, 'P')}`),
  getStatsOverview: () => requests.get<StatsOverview>(`/SystemAdminReports/getStats`),
  getSubscriptionGap: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<SubscriptionGap[]>>(
        `/SystemAdminReports/getSubscriptionGap`,
        {
          params,
        }
      )
      .then(responseBody),
  getSubscriptionGapOverview: (params?: URLSearchParams) =>
    axios
      .get<SubscriptionGapOverview>(
        `/SystemAdminReports/getSubscriptionGapOverview`,
        {
          params,
        }
      )
      .then(responseBody),      
  getExtensionReport: () =>
    requests.get<ExtensionReport>(`/SystemAdminReports/getExtensionReport`),
  loadStats: () => requests.post<void>(`/SystemAdminReports/loadStats`, {}),
  loadStatsCompare: (params: URLSearchParams) =>
    axios
      .get<StatsOverview>(`/SystemAdminReports/getStatsCompare`, {
        params,
      })
      .then(responseBody),

  getMultipleRecurring: () =>
    requests.get<MultipleRecurring[]>(`/SystemAdminReports/getMultipleRecurring`),

  getRecurringChargesReport: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<RecurringChargesReport[]>>(
        `/SystemAdminReports/getRecurringChargesReport`,
        {
          params,
        }
      )
      .then(responseBody),
  getRecurringChargesTotal: (params?: URLSearchParams) =>
    axios
      .get<RecurringChargesTotal>(
        `/SystemAdminReports/getRecurringChargesTotal`,
        {
          params
        }
      )
      .then(responseBody),  

  getCancellationReport: (params: URLSearchParams) =>
    axios
      .get<CancellationReport[]>(`/SystemAdminReports/getCancellationReport`, {
        params,
      })
      .then(responseBody),
  getNextChargeAdjustments: () =>
    requests.get<NextChargeAdjustments>(
      `/SystemAdminReports/getNextChargeAdjustments`
    ),
  getEarlyChargeReport: () =>
    requests.get<EarlyChargeReport>(`/SystemAdminReports/getEarlyChargeReport`),
  getTrialActivityReport: () =>
    requests.get<TrialActivityReport[]>(`/SystemAdminReports/getTrialActivityReport`),
  getSubscriberReport: () =>
    requests.get<SubscriberReport[]>(`/SystemAdminReports/getSubscriberReport`),
  
  getClientUserStats: () =>
    requests.get<ClientUserStats[]>(`/SystemAdminReports/getClientUserStats`),
  getClientUserTotalStat: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<ClientUserTotalStat[]>>(
        `/SystemAdminReports/listClientUserTotalStat`,
        { params }
      )
      .then(responseBody),
  getActiveCompanyUserTotal: () =>
    requests.get<number>(`/SystemAdminReports/getActiveCompanyUserTotal`),
  getClientNoLoginReport: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<ClientNoLoginReport[]>>(
        `/SystemAdminReports/getClientNoLoginReport`,
        { params }
      )
      .then(responseBody),
  getActiveServiceTypes: () =>
    requests.get<ActiveServiceType[]>(`/SystemAdminReports/getActiveServiceTypes`),
}