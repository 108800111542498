import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Grid, Tab } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import QuickVin from "./QuickVin";
import Import from "./Import";
import { useStore } from "../../../app/stores/store";
import PaymentProcessing from "./PaymentProcessing";
import PageHeader from "../../../app/layout/PageHeader";
import SettingsNavBar from "../SettingsNavBar";
import { Application, ClientStatus } from "../../../app/models/enums";
import SettingsDropdownMenuMobile from "../SettingsDropdownMenuMobile";
import { Link } from "react-router-dom";
interface Props {
  activeTab: number;
}

export default observer(function Advanced(props: Props) {
  const { t } = useTranslation(["common", "advancedSettings"]);
  const { pricingStore, userStore } = useStore();
  const { setActiveTab, activeTab } = pricingStore;
  const { checkStatus, checkApplication } = userStore;

  useEffect(() => {
    setActiveTab(props.activeTab);
  }, [setActiveTab, props.activeTab]);

  const panes = [
    {
      menuItem: {
        as: Link,
        content: t("quickvin", { ns: "common" }),
        to: "/settings/quickvin",
        exact: true,
      },
      render: () => <QuickVin />,
    },
  ];

  if (
    checkStatus(ClientStatus.Trial | ClientStatus.Active) &&
    checkApplication(Application.RealTimeLaborGuidePro)
  ) {
    panes.push({
      menuItem: {
        as: Link,
        content: t("import", { ns: "common" }),
        to: "/settings/import",
        exact: true,
      },
      render: () => <Import />,
    });
    panes.push({
      menuItem: {
        as: Link,
        content: t("paymentprocessing", { ns: "common" }),
        to: "/settings/paymentprocessing",
        exact: true,
      },
      render: () => <PaymentProcessing />,
    });
  }

  return (
    <Grid stackable>
      <Grid.Column width={3} className="mobileHeight">
        <SettingsNavBar />
        <SettingsDropdownMenuMobile />
      </Grid.Column>
      <Grid.Column width={13}>
        <PageHeader
          header={t("advanced", { ns: "common" })}
          type={"h1"}
          divider={true}
          getAlerts={true}
          addTitle={false}
        />
        <Tab
          menu={{ fluid: true }}
          menuPosition="left"
          panes={panes}
          activeIndex={activeTab}
          onTabChange={(e, data) => setActiveTab(data.activeIndex)}
        />
      </Grid.Column>
    </Grid>
  );
});
