import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import {
  Button,
  Divider,
  Grid,
  Message,
  Table,
} from "semantic-ui-react";
import PageHeader from "../../../app/layout/PageHeader";
import { useStore } from "../../../app/stores/store";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default observer(function ManageAnnouncements() {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();
  const { announcementStore } = useStore();
  const { loadAnnouncementList, getAnnouncementList } = announcementStore;

  useEffect(() => {
    loadAnnouncementList();
  }, [loadAnnouncementList]);

  return (
    <>
      <Grid columns={2}>
        <Grid.Column>
          <PageHeader
            header="Manage Announcements"
            type={"h1"}
            divider={false}
            addTitle={true}
          />
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Button
            color="green"
            icon="plus"
            content="Add New Announcement"
            onClick={() => navigate(`/admin/AddAnnouncement/`)}
          />
        </Grid.Column>
      </Grid>
      <Divider />
      <>
        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Area</Table.HeaderCell>
              <Table.HeaderCell>Is Active</Table.HeaderCell>
              <Table.HeaderCell>Date Created</Table.HeaderCell>
              <Table.HeaderCell>Date Start</Table.HeaderCell>
              <Table.HeaderCell>Date End</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {getAnnouncementList && getAnnouncementList.length > 0 ? (
              getAnnouncementList.map((x) => (
                <Table.Row key={`announcement.${x.id}`}>
                  <Table.Cell>
                    <Button
                      size="mini"
                      icon="eye"
                      onClick={() =>
                        navigate(`/admin/ViewAnnouncement/${x.id}`)
                      }
                    />
                    <Button
                      size="mini"
                      icon="edit"
                      color="blue"
                      onClick={() =>
                        navigate(`/admin/UpdateAnnouncement/${x.id}`)
                      }
                    />
                  </Table.Cell>
                  <Table.Cell>{x.id}</Table.Cell>
                  <Table.Cell>Log In</Table.Cell>
                  <Table.Cell> {x.isActive ? "Yes" : ""} </Table.Cell>
                  <Table.Cell>{format(x.createdDate!, "Pp")}</Table.Cell>
                  <Table.Cell>{format(x.startDate!, "Pp")}</Table.Cell>

                  <Table.Cell>{format(x.endDate!, "Pp")}</Table.Cell>
                  <Table.Cell>
                    <Button color="red" size="mini" icon="trash" onClick={() => {}}/>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row key={"noitems"}>
                <Table.Cell width="8" colSpan={8}>
                  <Message
                    color="yellow"
                    className="textAlignCenter fontStyleItalic"
                  >
                    {t("grid_noItems", { ns: "common" })}
                  </Message>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </>
    </>
  );
});
