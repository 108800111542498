import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Menu, Tab } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import IpLookup from "./IpLookup";
import ManageAnnouncements from "./ManageAnnouncements";
import { Link, useNavigate } from "react-router-dom";
import TrialEmails from "./TrialEmails";

interface Props {
  activeSubTab: number;
}

export default observer(function ToolsTabs(props: Props) {
  const { t } = useTranslation(["common", "systemAdmin"]);
  const navigate = useNavigate();

  const { systemAdminStore } = useStore();
  const { setActiveSubTab, activeSubTab } = systemAdminStore;

  const panes = [
    {
      menuItem: {
        as: Link,
        content: t("tools.ip", { ns: "systemAdmin" }),
        to: "/admin/Tools/IpLookup",
        exact: true,
      },
      render: () => (
        <Tab.Pane active={0 === activeSubTab}>
          <IpLookup />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        as: Link,
        content: "Manage Announcements",
        to: "/admin/Tools/ManageAnnouncements",
        exact: true,
      },
      render: () => (
        <Tab.Pane active={1 === activeSubTab}>
          <ManageAnnouncements />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        as: Link,
        content: "Free Trial Requests",
        to: "/admin/Tools/TrialEmails",
        exact: true,
      },
      render: () => (
        <Tab.Pane active={2 === activeSubTab}>
          <TrialEmails />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <>
      <Tab
        menu={{ fluid: true }}
        menuPosition="left"
        panes={panes}
        activeIndex={activeSubTab}
        onTabChange={(e, data) => {
          setActiveSubTab(data.activeIndex);
        }}
      />
    </>
  );
});
