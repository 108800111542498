import { useField } from "formik";
import React from "react";
import { Form, Label } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
interface Props {
  name: string;
  placeholder?: string;
  label?: string;
  className?: string;
  setFieldValue?: (name: string, value: any) => void
}

export default function MyDateInput({
  name,
  placeholder,
  label,
  className,
  setFieldValue,
}: Readonly<Props>) {
  const [field, meta] = useField(name);
  let labelClassName: string = "myLabel";
  let inputClassName: string = "input";
  if (className) labelClassName = `${labelClassName} ${className}`;
  if (className) inputClassName = `${inputClassName} ${className}`;

  return (
    <Form.Field error={meta.touched && !!meta.error} className="ui" name={name}>
      {label && label.length > 0 && (
        <label className={labelClassName} htmlFor={name}>
          {`${label} `}
        </label>
      )}
      <SemanticDatepicker
        name={name}
        placeholder={placeholder}
        className={inputClassName}
        format="MM/DD/YYYY"
        value={(field.value ? new Date(field.value) : undefined) || undefined}
        onChange={(e, data) => setFieldValue?.(name, data.value)}
        id={name}
      />
      {meta.touched && meta.error && (
        <Label basic color="red">
          {meta.error}
        </Label>
      )}
    </Form.Field>
  );
}
