import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../../api/agent";
import { CancellationReport } from "../../../models/systemAdmin";

export default class CancellationReportStore {
  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.predicate.keys(),
      () => {
        this.cancellationReportRegistry.clear();
        this.loadCancellationReport();
      }
    );
  }

  loadingInitial = false;
  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };
  predicate = new Map().set("all", true);
  cancellationReportRegistry = new Map<Date, CancellationReport>();

  loadCancellationReport = async () => {
    this.loadingInitial = true;
    try {
      this.cancellationReportRegistry.clear();
      const result = await agent.SystemAdminReports.getCancellationReport(
        this.axiosParams
      );
      runInAction(() => {
        result.forEach((cancellationReport) => {
          this.setCancellationReport(cancellationReport);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  private setCancellationReport = (cancellationReport: CancellationReport) => {
    cancellationReport.date = new Date(cancellationReport.date);
    this.cancellationReportRegistry.set(
      cancellationReport.date,
      cancellationReport
    );
  };

  get getCancellationReport() {
    return Array.from(this.cancellationReportRegistry.values()).sort(
      (a, b) => b.date!.getTime() - a.date!.getTime()
    );
  }

  get axiosParams() {
    const params = new URLSearchParams();
    this.predicate.forEach((value, key) => {
      if (key === "StartDate" || key === "EndDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  setPredicate = (startDate?: Date, endDate?: Date) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        if (key !== "startDate") this.predicate.delete(key);
      });
    };

    resetPredicate();
    if (startDate) this.predicate.set("StartDate", startDate);
    if (endDate) this.predicate.set("EndDate", endDate);
  };
}
