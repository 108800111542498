import React from "react";
import { observer } from "mobx-react-lite";
import PageHeader from "../../../app/layout/PageHeader";

export default observer(function SignUp() {

  return (
    <>
     <PageHeader
              type="h1"
              header={"Coming Soon"}
              className="modal-text-color"
              textAlign="left"
              divider
              addTitle
            />

    </>
  );
});