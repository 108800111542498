import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Grid,
  GridColumn,
  Header,
  Message,
  Table,
} from "semantic-ui-react";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useStore } from "../../app/stores/store";
import { useTranslation } from "react-i18next";
import PageHeader from "../../app/layout/PageHeader";
import {
  convertApplicationToString,
  convertPaymentStatusToString,
  convertSubscriptionTypeToString,
  formatCurrency,
  getNegative,
} from "../../app/common/util/functions";
import { format } from "date-fns";
import SubscriptionMobileMenu from "./SubscriptionMobileMenu";
import SubscriptionContent from "./SubscriptionContent";
import {
  Application,
  ClientStatus,
  PaymentStatus,
  TransactionType,
} from "../../app/models/enums";
import { PagingParams } from "../../app/models/pagination";
import MyPaging from "../../app/common/form/MyPaging";

export default observer(function Status() {
  const { subscriptionStore, userStore } = useStore();
  const {
    loadingInitial,
    getCurrentSubscription,
    clientSubscriptionStatus,
    loadClientSubscriptionPayments,
    getSubscriptionPayments,
    createSubscriptionExtension,
    pagination,
    setPagingParams,
    pagingParams,
  } = subscriptionStore;

  const { user, initialRedirects, getUser } = userStore;

  const { t } = useTranslation(["common", "subscription"]);

  useEffect(() => {
    getCurrentSubscription()
      .then(() => {
        loadClientSubscriptionPayments();
      })
      .catch();
  }, [getCurrentSubscription]);
  
    function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
      loadClientSubscriptionPayments();
    }
  }

  const [disabledButton, setDisableButton] = useState<boolean>(false);
  const buttonClassName =
    user?.application === Application.RealTimeLaborGuide
      ? "RTLG_button"
      : "RTP_button";

  if (loadingInitial)
    return <LoadingComponent content={t("loading", { ns: "subscription" })} />;

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <SubscriptionMobileMenu
              isCancelled={clientSubscriptionStatus.isCancelled}
            />
            <SubscriptionContent
              isCancelled={clientSubscriptionStatus.isCancelled}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <PageHeader
        header={t("subscription.status", { ns: "subscription" })}
        type={"h1"}
        divider={true}
        getAlerts={true}
        addTitle={true}
      />
      {user &&
        user.status &&
        user?.status &&
        user.status === (ClientStatus.Expired | ClientStatus.Trial) && (
          <Message info>
            {`${t("expiredtrial", { ns: "subscription" })} `}
            <Link to="/subscription/AvailableTrials">
              {t("availabletrialextensions", { ns: "subscription" })}
            </Link>
          </Message>
        )}

      {user &&
        user.status &&
        user?.status &&
        user.status === (ClientStatus.Expired | ClientStatus.Subscription) && (
          <Message negative>
            {`${t("subscription.subscriptionExtension", { ns: "subscription" })} `}
            <Button
              className={`vertical-align-middle ${buttonClassName}`}
              floated="right"
              disabled={disabledButton}
              loading={disabledButton}
              onClick={() => {
                setDisableButton(true);
                  createSubscriptionExtension(user?.clientId ?? "", false)
                    .then(() => {
                      getUser()
                        .then(() => {
                          initialRedirects();
                        })
                        .catch()
                        .finally(() => {
                          setDisableButton(false);
                        });
                    })
                    .catch()
                    .finally(() => {
                      setDisableButton(false);
                    });
              }}
              size="mini"
            >
              {t("trial.extend", { ns: "subscription" })}
            </Button>
          </Message>
        )}
      <Card.Group stackable itemsPerRow="2">
        <Card>
          <Card.Header className="card-header">
            {t("current", { ns: "common" })}:
          </Card.Header>
          <Card.Content>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column textAlign="right">
                  {t("last_Payment_date", { ns: "subscription" })}:
                </Grid.Column>
                <Grid.Column>
                  {clientSubscriptionStatus.lastPaymentDate &&
                  !clientSubscriptionStatus.isTrial
                    ? format(clientSubscriptionStatus.lastPaymentDate, "P")
                    : clientSubscriptionStatus.isTrial &&
                      `(${t("trial.trialAccount", { ns: "subscription" })})`}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column textAlign="right">
                  {t("service_type.label", { ns: "common" })}:
                </Grid.Column>
                <Grid.Column>
                  {convertApplicationToString(
                    clientSubscriptionStatus.application
                  )}
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={2}>
                <Grid.Column textAlign="right">
                  {t("last_payment", { ns: "subscription" })}:
                </Grid.Column>
                <Grid.Column>
                  {formatCurrency(
                    clientSubscriptionStatus.lastPaymentAmount ?? 0.0
                  )}
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={2}>
                <Grid.Column textAlign="right">
                  {t("available_users", { ns: "subscription" })}:
                </Grid.Column>
                <Grid.Column>{`${clientSubscriptionStatus.activeUsers} ${t(
                  "activeUsers",
                  { ns: "subscription" }
                )} ${
                  clientSubscriptionStatus.isTrial
                    ? 0
                    : clientSubscriptionStatus.subscriptionUsers
                } ${t("paid", { ns: "subscription" })}`}</Grid.Column>
              </Grid.Row>
            </Grid>
          </Card.Content>
        </Card>
        <Card>
          <Card.Header className="card-header">
            {t("upcoming", { ns: "common" })}:
          </Card.Header>
          <Card.Content>
            {!clientSubscriptionStatus.isTrial &&
              !clientSubscriptionStatus.isCancelled && (
                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column textAlign="right">
                      {t("upcoming.renewal", { ns: "subscription" })}:
                    </Grid.Column>
                    <Grid.Column>
                      {clientSubscriptionStatus.renewalDate &&
                        format(clientSubscriptionStatus.renewalDate, "P")}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column textAlign="right">
                      {t("service_type.label", { ns: "common" })}:
                    </Grid.Column>
                    <Grid.Column>
                      {convertApplicationToString(
                        clientSubscriptionStatus.renewalApplication ?? 0
                      )}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column textAlign="right">
                      {t("upcoming.next_pay", { ns: "subscription" })}:
                    </Grid.Column>
                    <Grid.Column>
                      {clientSubscriptionStatus.renewalPaymentAmount
                        ? formatCurrency(
                            clientSubscriptionStatus.renewalPaymentAmount
                          )
                        : formatCurrency(0.0)}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column textAlign="right">
                      {t("upcoming.users", { ns: "subscription" })}:
                    </Grid.Column>
                    <Grid.Column>
                      {clientSubscriptionStatus.renewalUsers}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column textAlign="right">
                      {t("upcoming.next_renew", { ns: "subscription" })}:
                    </Grid.Column>
                    <Grid.Column>
                      {clientSubscriptionStatus.renewalPeriod}{" "}
                      {t("days", { ns: "common" })}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}
            {!clientSubscriptionStatus.isTrial &&
              clientSubscriptionStatus.isCancelled && (
                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column textAlign="right">
                      {t("cancel.cancelledOn", { ns: "subscription" })}:
                    </Grid.Column>
                    <Grid.Column>
                      {clientSubscriptionStatus.cancelledDate &&
                        format(clientSubscriptionStatus.cancelledDate, "P")}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column textAlign="right">
                      {t("cancel.lastDay", { ns: "subscription" })}:
                    </Grid.Column>
                    <Grid.Column>
                      {clientSubscriptionStatus.renewalDate &&
                        format(clientSubscriptionStatus.renewalDate, "P")}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}
          </Card.Content>
        </Card>
      </Card.Group>
      <Header sub>{t("paymenthistory", { ns: "subscription" })}</Header>
      <Table striped unstackable className="dnxTable">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("date", { ns: "common" })}</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              {t("amount", { ns: "common" })}
            </Table.HeaderCell>
            <Table.HeaderCell>{t("status", { ns: "common" })}</Table.HeaderCell>
            <Table.HeaderCell>
              {t("maskedLast4", { ns: "subscription" })}
            </Table.HeaderCell>
            <Table.HeaderCell>{t("type", { ns: "common" })}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {getSubscriptionPayments && getSubscriptionPayments.length > 0 ? (
            getSubscriptionPayments.map((item) => (
              <Table.Row key={`paymentHistory.[${item.clientBillingId}]`}>
                <Table.Cell
                  className={
                    item.status === PaymentStatus.Pending
                      ? "fontStyleItalic"
                      : ""
                  }
                >
                  {item.createdDate &&
                    format(
                      item.createdDate,
                      item.status === PaymentStatus.Pending ? "P" : "P p"
                    )}
                </Table.Cell>
                <Table.Cell
                  verticalAlign={"middle"}
                  className="textAlignRightNotMobile"
                  style={{
                    color: getNegative(Number(item.amount ?? 0)),
                  }}
                >
                  {formatCurrency(item.amount ?? 0)}
                </Table.Cell>
                <Table.Cell
                  className={
                    item.status === PaymentStatus.Pending
                      ? "fontStyleItalic"
                      : ""
                  }
                >
                  {item.transactionType === TransactionType.Refund
                    ? t("transactionTypes.refund", { ns: "subscription" })
                    : convertPaymentStatusToString(item.status)}
                </Table.Cell>
                <Table.Cell>{item.creditCardLast4}</Table.Cell>
                <Table.Cell>
                  {convertSubscriptionTypeToString(item.subscriptionType)}
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row key={"noItems"}>
              <Table.Cell width="5" colSpan={5} className="noPaddingLeft">
                <Message
                  color="yellow"
                  className="textAlignCenter fontStyleItalic"
                >
                  {t("grid_noItems", { ns: "common" })}
                </Message>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
              <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="9">
            <MyPaging
              itemCount={pagination ? pagination.totalItems : 10}
              pageSize={pagingParams.pageSize}
              currentPage={pagination ? pagination.currentPage : 1}
              onClick={listOnClick}
              onChange={listOnClick}
              floated="right"
            ></MyPaging>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
      </Table>
    </>
  );
});
