import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { Grid, Divider, Button } from "semantic-ui-react";
//import * as d3 from "d3";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { useStore } from "../../../app/stores/store";
import PageHeader from "../../../app/layout/PageHeader";

export default observer(function CancellationReport() {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [data] = useState([0, 3, 5, 1, 0, 6, 4, 1]);
  const { cancellationReportStore } = useStore();
  const {
    loadCancellationReport,
    getCancellationReport,
    cancellationReportRegistry,
  } = cancellationReportStore;

  const [startDate, setStartDate] = useState<Date | Date[] | undefined>(
    undefined
  );

  const [endDate, setEndDate] = useState<Date | Date[] | undefined>(undefined);

  useEffect(() => {
    loadCancellationReport();
  }, [loadCancellationReport]);

  function listOnClick(value: number) {
    cancellationReportRegistry.clear();
  }

  // useEffect(() => {
  //   setting up svg
  //   const w= 1000;
  //   const h= 200;
  //   const svg = d3.select(svgRef.current)
  //     .attr('width', w)
  //     .attr('height', h)
  //     .style('background', '#d3d3d3')
  //     .style('overflow', 'visible');

  //   setting the scaling
  //   const xScale = d3.scaleLinear()
  //     .domain([0, data.length -1])
  //     .range([0, w]);
  //   const yScale = d3.scaleLinear()
  //     .domain([0, data.length -1])
  //     .range([h, 0]);
  //   const generateScaledLine = d3.line()
  //   .x((d, i) => xScale(i))
  //   // .y(yScale)
  //   .curve(d3.curveCardinal);

  //   //setting the axes
  //   const xAxis = d3.axisBottom(xScale)
  //     .ticks(data.length)
  //     // .tickFormat(i => i + 1);
  //     const yAxis = d3.axisLeft(yScale)
  //     .ticks(5);
  //     svg.append('g')
  //       .call(xAxis)
  //       .attr('transform', `translate(0, ${h})`)
  //     svg.append('g')
  //       .call(yAxis);

  //   setting up the data for the svg
  //   svg.selectAll('.line')
  //     .data([data])
  //     .join('path')
  //       .attr('d', d => generateScaledLine(d))
  //       .attr('fill', 'none')
  //       .attr('stroke', 'black')
  // }, [data])

  return (
    <>
      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column>
            <PageHeader
              type="h2"
              header="Cancellation Report"
              className="modal-text-color"
              textAlign="left"
              divider
              addTitle
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid stackable>
        <Grid.Row columns={3}>
          <Grid.Column>
            <label>{"Start Date:"}</label>{" "}
            <SemanticDatepicker
              name="startDate"
              placeholder="Start Date"
              onChange={(e, d) => {
                setStartDate(d.value ? d.value : undefined);
              }}
            />{" "}
          </Grid.Column>
          <Grid.Column>
            <label>{"End Date:"}</label>{" "}
            <SemanticDatepicker
              name="endDate"
              placeholder="End Date"
              onChange={(e, d) => {
                setEndDate(d.value ? d.value : undefined);
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <Button
              color="green"
              className="save_button"
              icon="check"
              content={"Get Report"}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <svg ref={svgRef} />
        </Grid.Row>
      </Grid>
    </>
  );
});
