import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Table } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import { format } from "date-fns";
import { PagingParams } from "../../../app/models/pagination";
import MyPaging from "../../../app/common/form/MyPaging";
import PageHeader from "../../../app/layout/PageHeader";


export default observer(function CarfaxNightly() {
  const { t } = useTranslation(["common", "systemAdmin"]);
  const { quickVinNightlyLogStore } = useStore();
  const {
    loadQuickVinNightlyLog,
    getQuickVinNightlyLog,
    QuickVinNightlyLogRegistry,
    pagingParams,
    pagination,
    setPagingParams,
  } = quickVinNightlyLogStore;

  useEffect(() => {
    loadQuickVinNightlyLog();
  }, [loadQuickVinNightlyLog]);

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
      QuickVinNightlyLogRegistry.clear();
    }
  }

  return (
    <>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column>
            <PageHeader
              type="h1"
              header={t("logs.carfax_intro", { ns: "systemAdmin" }).toString()}
              className="modal-text-color"
              textAlign="left"
              divider
              addTitle
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid stackable>
        <Grid.Column stackable>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  {t("logs.created", { ns: "systemAdmin" })}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("logs.writeable", { ns: "systemAdmin" })}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("logs.companies", { ns: "systemAdmin" })}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("logs.files", { ns: "systemAdmin" })}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("logs.rows", { ns: "systemAdmin" })}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("logs.zipped", { ns: "systemAdmin" })}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("logs.ftp", { ns: "systemAdmin" })}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {getQuickVinNightlyLog &&
                getQuickVinNightlyLog.length > 0 &&
                getQuickVinNightlyLog.map((x) => (
                  <Table.Row key={`carFaxNightly}`}>
                    <Table.Cell>{format(x.createdDate, "P, p")}</Table.Cell>
                    <Table.Cell>{"No API info"}</Table.Cell>
                    <Table.Cell>{x.clientIdsCount}</Table.Cell>
                    <Table.Cell>{x.fileCount}</Table.Cell>
                    <Table.Cell>{x.rowCount}</Table.Cell>
                    <Table.Cell>{"No API info"}</Table.Cell>
                    <Table.Cell>{x.isSuccess ? "Yes" : "No"}</Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="8">
                  <MyPaging
                    itemCount={pagination ? pagination.totalItems : 50}
                    pageSize={pagingParams.pageSize}
                    currentPage={pagination ? pagination.currentPage : 1}
                    onClick={listOnClick}
                    onChange={listOnClick}
                    floated="right"
                  ></MyPaging>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Grid.Column>
      </Grid>
    </>
  );
});
