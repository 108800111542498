import { observer } from "mobx-react-lite";
import React from "react";
import { Button, Message, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../app/stores/store";
import { format } from "date-fns";
import ConfirmDelete from "../../app/common/form/ConfirmDelete";
import { PagingParams } from "../../app/models/pagination";
import MyPaging from "../../app/common/form/MyPaging";
import { Vehicle } from "../../app/models/vehicle";
import CustomerVehicleForm from "../customerVehicle/CustomerVehicleForm";
import VinDisplay from "../../app/common/form/VinDisplay";

interface Props {
  customerId: string;
  capitalizeFirstCharacter: boolean;
  setIsFiltered: (value: boolean) => void;
}

export default observer(function CustomerDetailVehicleList({
  customerId,
  capitalizeFirstCharacter,
  setIsFiltered,
}: Props) {
  const { t } = useTranslation(["common", "customer"]);

  const { modalStore, customerVehicleStore, customerTicketStore, vehicleStore } = useStore();

  const {setSelectedVehicle} = vehicleStore

  const {
    customerVehicles,
    loadCustomerVehicles,
    resetCustomerVehicleRegistry,
    removeCustomerVehicle,
    updateCustomerVehicle,
    setPagingParams,
    pagination,
    pagingParams,
  } = customerVehicleStore;
  const { resetCustomerTicketRegistry, loadCustomerTickets, setPredicate } =
    customerTicketStore;

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
      resetCustomerVehicleRegistry();
      loadCustomerVehicles(customerId);
    }
  }

  return (
    <Table striped unstackable className="dnxTable">
      <Table.Header>
        <Table.Row key="vehicleHeader">
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell>
            {`${t("date", { ns: "common" })}`}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {`${t("vehicledescription", { ns: "common" })}`}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {`${t("vin", { ns: "customer" })}`}
          </Table.HeaderCell>
          <Table.HeaderCell>{`${t("tag", { ns: "common" })}`}</Table.HeaderCell>
          <Table.HeaderCell>
            {`${t("vehicle", { ns: "common" })}`}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {`${t("mileage", { ns: "common" })}`}
          </Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {customerVehicles && customerVehicles.length !== 0 ? (
          customerVehicles.map((vehicle: Vehicle) => (
            <Table.Row key={`vehicle.${vehicle.id}`}>
              <Table.Cell className="textAlignLeft">
                <Button
                  color="grey"
                  icon="edit"
                  type="button"
                  onClick={() => {
                    setSelectedVehicle(vehicle);
                    modalStore.openModal(
                      <CustomerVehicleForm
                        updateValue={updateCustomerVehicle}
                        isCustomerEdit={true}
                        autoCapitalizeFirstLetter={capitalizeFirstCharacter}
                        updateData={false}
                      />
                    );
                  }}
                ></Button>
              </Table.Cell>
              <Table.Cell data-label={`${t("date", { ns: "common" })}:`}>
                {vehicle.createdDate && format(vehicle.createdDate, "Pp")}
              </Table.Cell>
              <Table.Cell data-label={`${t("vehicle", { ns: "common" })}:`}>
                {(vehicle.year ?? 0) > 1 && `${vehicle.year} `}
                        {vehicle.makeName && `${vehicle.makeName ? vehicle.makeName : ""} `}
                        {`${vehicle.modelName} `}
              </Table.Cell>
              <Table.Cell data-label={`${t("vin", { ns: "customer" })}:`}>
                <VinDisplay vin={vehicle.vin ?? ""} />
              </Table.Cell>
              <Table.Cell data-label={`${t("tag", { ns: "common" })}:`}>
                {vehicle.tag}
              </Table.Cell>
              <Table.Cell data-label={`${t("vehicle", { ns: "common" })}:`}>
                {vehicle.vehicleNumber}
              </Table.Cell>
              <Table.Cell data-label={`${t("mileage", { ns: "common" })}:`}>
                {vehicle.mileageOut}
              </Table.Cell>
              <Table.Cell className="textAlignRight">
                <Button
                  color="grey"
                  icon="trash"
                  onClick={() => {
                    modalStore.openModal(
                      <ConfirmDelete
                        header={t("removeVehicle", {
                          ns: "customer",
                        })}
                        text={t("removeVehicleText", {
                          ns: "customer",
                        })}
                        value={vehicle.id ? vehicle.id : ""}
                        deleteValue={(d) => {
                          removeCustomerVehicle(d).then(() => {});
                        }}
                      />
                    );
                  }}
                ></Button>
                <Button
                  color="grey"
                  icon="filter"
                  type="button"
                  onClick={() => {
                    setIsFiltered(true);
                    setPredicate("vehicleId", vehicle.id ?? "");
                    setPagingParams(new PagingParams());
                    resetCustomerTicketRegistry();
                    loadCustomerTickets(customerId ?? "");
                  }}
                ></Button>
              </Table.Cell>
            </Table.Row>
          ))
        ) : (
          <Table.Row key={"noItems2"}>
            <Table.Cell className="noPaddingLeft" width="9" colSpan={9}>
              <Message
                color="yellow"
                className="textAlignCenter fontStyleItalic"
              >
                {t("grid_noItems", { ns: "common" })}
              </Message>
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.Cell colSpan="9">
            <MyPaging
              itemCount={pagination ? pagination.totalItems : 10}
              pageSize={pagingParams.pageSize}
              currentPage={pagination ? pagination.currentPage : 1}
              onClick={listOnClick}
              onChange={listOnClick}
              floated="right"
            />
          </Table.Cell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
});
